import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";
import H2 from "../../../components/headings/H2";
import { deleteJob } from "../../services/JobServices";
import { MdReorder } from "react-icons/md";

function JobList() {
  const navigate = useNavigate();
  const jobTable = useRef();
  const isFrozen = window.innerWidth <= 768 ? false : true;
  const deleteSelectedJob = (id) => {
    if (!window.confirm("Do You really want to delete this Opening?")) return;

    deleteJob(id)
      .then((res) => {
        toast.success(res.data.message);
        jobTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="job-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="job-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Title",
      field: "title",
      minWidth: "270",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Role",
      minWidth: "270",
      field: "role",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Hiring",
      field: "currently_hiring",
      resizable: true,
      headerSort: true,
      minWidth: "120",
      hozAlign: "center",
      responsive: 0,
      vertAlign: "middle",
      formatter: "tickCross",
    },
    {
      title: "Department",
      field: "department_id",
      resizable: true,
      sorter: "string",
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      minWidth: "120",
      hozAlign: "center",
      responsive: 0,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        //cell - the cell component
        //formatterParams - parameters set for the column
        //onRendered - function to call when the formatter has been rendered

        return cell.getRow().getData().department_name || "";
        //return the contents of the cell;
      },
    },
    {
      title: "Experience",
      field: "experience",
      minWidth: "250",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      formatter: "textarea",
    },
    {
      title: "Salary",
      field: "salary",
      resizable: true,
      minWidth: "250",
      responsive: 0,
      vertAlign: "middle",
      formatter: "textarea",
    },
    {
      title: "Skills",
      vertAlign: "middle",
      headerSort: false,
      minWidth: "450",
      responsive: 0,
      field: "skills",
      formatter: "textarea",
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
      frozen: isFrozen,
    },
  ];

  useEffect(() => {
    $(document).on("click", ".job-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/jobs/edit/${cell}`);
    });

    $(document).on("click", ".job-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedJob(dataId);
    });

    return () => {
      $(document).off("click", ".job-edit-btn");
      $(document).off("click", ".job-delete-btn");
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Job Openings</H2>
        <div className="flex gap-4">
          <Link
            to="/jobs/changeorder"
            className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-800 lg:px-12 lg:py-2"
          >
            <MdReorder />
            Reorder
          </Link>
          <Link
            to="/jobs/add"
            className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-800 lg:px-12 lg:py-2"
          >
            <FaPlusSquare /> Add New
          </Link>
        </div>
      </ComponentHeader>
      <div className="h-full">
        <DataTable
          ref={jobTable}
          ajaxUrl={`${getAdminUrl()}/job-opening`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default JobList;
