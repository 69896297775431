import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectSearch from "../../../components/inputs/SelectSearch";
import PhoneNumberInput from "../../../../components/inputFields/PhoneInput";
import InputLabel from "../../../components/inputs/InputLabel";
import GiftCardSenderForm from "./GiftCardSenderForm";
import GiftCardRecieverForm from "./GiftCardRecieverForm";
import { addGiftCardIssued, getGiftCardCategories, getGiftCardThemeByCategoryId, getGiftCardThemes, updateGiftCardIssued } from "../../../services/GiftCardServices";
import { useNavigate } from "react-router-dom";
import { getUploadsUrl } from "../../../../ServiceConfig";

const issuedGIftCardSchema = Yup.object().shape({
  sender_first_name: Yup.string().required("Required"),
  sender_last_name: Yup.string().required("Required"),
  sender_email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  sender_country_code: Yup.string().required("Required Sender Country Code"),
  sender_phone: Yup.number().required("Required Sender Phone number"),
  gift_card_category_id: Yup.string().required("Required"),
  gc_category_theme_id: Yup.string().required("Required"),
  greet: Yup.string(),
  message: Yup.string(),
  amount: Yup.number().min(1, "Should be Greater than 0").max(50000).required("Required"),
  recipient_first_name: Yup.string(),
  recipient_last_name: Yup.string(),
  recipient_email: Yup.string()
    .email('Invalid email address'),
  recipient_country_code: Yup.string(),
  recipient_phone: Yup.number(),
});

function FormIssuedGiftCards({ formData, edit, id }) {

  // Bulk File uploading Logic

  // --------------------------------------------------------------------------------------------


  const navigate = useNavigate()
  const [senderRecieverSame, setSenderRecieverSame] = useState(false)
  const [allGiftCardCategories, setAllGiftCardCategories] = useState([])
  const [allGiftCardThemes, setAllGiftCardThemes] = useState([])
  const [selectedGiftCardCategory, setSelectedGiftCardCategory] = useState(formData?.gift_card_category_id)
  const [selectedGCTheme, setSleectedGCTheme] = useState()
  const [themeImage, setThemeImage] = useState(null)

  const getAllGiftCardCategory = async () => {
    try {
      const res = await getGiftCardCategories()
      setAllGiftCardCategories(res.data.data.data.map((onecat) => ({
        value: onecat.id,
        label: onecat.name
      })))
    } catch (error) {
      console.log(error)
    }
  }

  const getAllGiftCardTheme = async (id) => {
    try {
      const res = await getGiftCardThemeByCategoryId(id)
      setAllGiftCardThemes(res.data.data.map((onetheme) => ({
        label: onetheme.name,
        value: onetheme.id,
        image: onetheme.blank_image
      })))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllGiftCardCategory()
  }, [])

  useEffect(() => {
    if (selectedGiftCardCategory) {
      getAllGiftCardTheme(selectedGiftCardCategory)
    }
  }, [selectedGiftCardCategory])

  const handleAddGiftCardIssued = async (submitData) => {
    try {
      const res = await addGiftCardIssued(submitData)
      toast.success(res.data.data)
      navigate('/gift-cards/issued')
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.log(error)
    }
  }

  const handleUpdateGiftCardIssued = async (submitData) => {
    try {
      const res = await updateGiftCardIssued(id, submitData)
      toast.success(res.data.message)
      navigate('/gift-cards/issued')
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.log(error)
    }
  }

  useEffect(() => {
    if (selectedGCTheme) {
      setThemeImage(allGiftCardThemes.filter(thm => thm.value === selectedGCTheme)[0].image)
    } else {
      setThemeImage()
    }
  }, [selectedGCTheme])
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          sender_first_name: formData?.sender_first_name ? formData.sender_first_name : "",
          sender_last_name: formData?.sender_last_name ? formData.sender_last_name : "",
          sender_email: formData?.sender_email ? formData.sender_email : "",
          sender_country_code: formData?.sender_country_code ? formData.sender_country_code : "",
          sender_phone: formData?.sender_phone ? formData.sender_phone : "",
          gift_card_category_id: formData?.gift_card_category_id ? formData.gift_card_category_id : "",
          gc_category_theme_id: formData?.gc_category_theme_id ? formData.gc_category_theme_id : "",
          greet: formData?.greet ? formData.greet : "",
          message: formData?.message ? formData.message : "",
          amount: formData?.amount ? formData.amount : 0,
          sender_user_id: formData?.sender_user_id ? formData.sender_user_id : null,
          recipient_first_name: formData?.recipient_first_name ? formData.recipient_first_name : "",
          recipient_last_name: formData?.recipient_last_name ? formData.recipient_last_name : "",
          recipient_email: formData?.recipient_email ? formData.recipient_email : "",
          recipient_country_code: formData?.recipient_country_code ? formData.recipient_country_code : "",
          recipient_phone: formData?.recipient_phone ? formData.recipient_phone : "",
        }}
        validationSchema={issuedGIftCardSchema}
        onSubmit={(values) => {
          edit ? handleUpdateGiftCardIssued(values) : handleAddGiftCardIssued(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4 lg:flex-nowrap">
              <div className="my-4 grow">
                <div className="flex lg:flex-row flex-col gap-4">
                  {/* //? Sender */}
                  <div className="grow basis-[45%] lg:max-w-[50%]">
                    <h3 className=" font-bold lg:text-xl">Sender's Details</h3>
                    <GiftCardSenderForm />
                  </div>

                  <div className="hidden lg:block w-[1px] bg-black"></div>

                  {/* Reciever */}
                  <div className="grow basis-[45%] lg:max-w-[50%]">
                    <div className="flex gap-6">
                      <h3 className=" font-bold lg:text-xl">Recipient Details</h3>
                      <div className="flex items-center space-x-1">
                        <input
                          id="sender-checkbox"
                          type="checkbox"
                          checked={senderRecieverSame}
                          onChange={(e) => {
                            setSenderRecieverSame(e.target.checked ? true : false)
                            if (e.target.checked) {
                              setFieldValue('recipient_first_name', values.sender_first_name)
                              setFieldValue('recipient_last_name', values.sender_last_name)
                              setFieldValue('recipient_email', values.sender_email)
                              setFieldValue('recipient_country_code', values.sender_country_code)
                              setFieldValue('recipient_phone', values.sender_phone)
                            }
                            else {
                              setFieldValue('recipient_first_name', "")
                              setFieldValue('recipient_last_name', "")
                              setFieldValue('recipient_email', "")
                              setFieldValue('recipient_country_code', "")
                              setFieldValue('recipient_phone', "")
                            }
                          }}
                          className="w-5 h-5 accent-blue-500 rounded-md border-gray-300 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all"
                        />
                        <label
                          htmlFor="sender-checkbox"
                          className="text-gray-700 font-medium cursor-pointer"
                        >
                          Same as Sender
                        </label>
                      </div>
                    </div>
                    <GiftCardRecieverForm />
                  </div>
                </div>



                <div className="flex flex-wrap gap-4">
                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel required={true} label="Gift Card Category" labelFor="gift_card_category_id" />
                    <SelectSearch
                      className='field'
                      value={values.gift_card_category_id}
                      options={
                        allGiftCardCategories
                      }
                      onChange={(value) => {
                        setFieldValue("gift_card_category_id", value)
                        setSelectedGiftCardCategory(value)
                        if (value !== selectedGiftCardCategory) {
                          setFieldValue("gc_category_theme_id", "")
                        }
                      }}
                    />
                    <ErrorMessage name="gift_card_category_id">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel required={true} label="Gift Card Theme" labelFor="gc_category_theme_id" />
                    <SelectSearch
                      className='field'
                      disabled={!selectedGiftCardCategory}
                      value={values.gc_category_theme_id}
                      options={
                        allGiftCardThemes
                      }
                      onChange={(value) => {
                        setSleectedGCTheme(value)
                        setFieldValue("gc_category_theme_id", value)
                      }}
                    />
                    {
                      themeImage &&
                      <img
                        src={`${getUploadsUrl()}/gift-cards/blank/${themeImage}`}
                        className="max-w-[200px] h-auto border-2 border-black"
                      />
                    }

                    <ErrorMessage name="gc_category_theme_id">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>



                <div className="flex flex-wrap lg:gap-4 gap-2">
                  <div className="my-4 grow basis-[45%] lg:max-w-[30%]">
                    <InputLabel required={true} label="Amount" labelFor="amount" />
                    <Field
                      type="number"
                      id="amount"
                      name="amount"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="amount">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="my-4 grow basis-[45%] lg:max-w-[30%]">
                    <InputLabel label="Greet" labelFor="greet" />
                    <Field
                      as="textarea"
                      id="greet"
                      name="greet"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="greet">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="my-4 grow basis-[45%] lg:max-w-[30%]">
                    <InputLabel label="Message" labelFor="message" />
                    <Field
                      as="textarea"
                      id="message"
                      name="message"
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                    />
                    <ErrorMessage name="message">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

              </div>
            </div>
            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary dark:bg-green-700">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FormIssuedGiftCards;
