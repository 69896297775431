import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import SingleJobApplicationCard from './SingleJobApplicationCard'
// import { toast } from "react-toastify";
import { getCAPDataById } from "../services/CapService";
import { getUploadsUrl } from "../../ServiceConfig";
import { FaGlobe } from "react-icons/fa";

const SingleApplication = () => {
  const [applicantData, setApplicantData] = useState([]);
  const [comment, setComment] = useState("");
  const params = useParams();

  const getSingleUserApplicationData = () => {
    getCAPDataById(params.id)
      .then((res) => {
        setApplicantData(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getSingleUserApplicationData();
  }, []);

  // 0 = Pending, 1 = Selected, 2 = Currently Not Hiring, 3 = Rejected
  // const statusArray = [
  //   "Pending",
  //   "Selected",
  //   "Currently Not Hiring",
  //   "Rejected",
  // ];

  // const [selectedStatus, setSelectedStatus] = useState('');

  // const handleStatusSelect = (status) => {
  //   setSelectedStatus(status);
  // };

  // const updateApplicationStatus = () => {
  //   updateJobApplicationStatus(applicantData?.application?.id, { status: statusArray.indexOf(selectedStatus), comment: comment }).then(res => {
  //     if (res.data.status === 'success') {
  //       toast.success(res.data.message)
  //       getSingleUserApplicationData()
  //       setComment('')
  //       setSelectedStatus('')
  //     }
  //   }).catch(e => {
  //     toast.error("Error in Updating Job Status")
  //     console.log(e)
  //   })

  // }

  // console.log(applicantData);
  // console.log(applicantData?.linkedin_profile);

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="w-full overflow-hidden rounded-lg bg-white shadow-md lg:w-1/2">
        <div className="p-6">
          {/* <h2 className="text-xl font-semibold mb-2">{applicantData?.application?.applied_for?.title}</h2> */}
          <h2 className="mb-2 text-xl font-semibold">
            {applicantData?.first_name} Applies for CAP
          </h2>
          {/* <p className="text-gray-600 mb-4">{applicantData?.application?.applied_for?.role}</p> */}
          <div className="border-t border-gray-200 pt-4">
            <p className="text-md text-gray-500">
              <strong>Applied By : </strong> {applicantData?.first_name}{" "}
              {applicantData?.last_name}
            </p>
            <p className="text-md text-gray-500">
              <strong>Email : </strong> {applicantData?.email}
            </p>
            <p className="text-md text-gray-500">
              <strong>Phone : </strong>+{applicantData?.country_code}{" "}
              {applicantData?.phone}
            </p>
            <p className="text-md text-gray-500">
              <strong>College/University : </strong>{" "}
              {applicantData?.institution}
            </p>
            <p className="text-md text-gray-500">
              <strong>About👇</strong>
            </p>
            {/* <p className="text-sm text-gray-500"><strong>Status:</strong> {statusArray[applicantData?.status]}</p> */}
            <p
              dangerouslySetInnerHTML={{
                __html: applicantData?.description,
              }}
            ></p>
          </div>
         <a href={`${getUploadsUrl()}/resume/${applicantData?.resume}`} rel="noopener" target="_blank"> <p className="mt-6 border-b-2 cursor-pointer border-blue-600 text-semibold w-max">Download Resume</p></a>
        </div>
        <div className="flex items-center justify-between bg-gray-100 px-6 py-4">
          {/* <a href={`${getUploadsUrl()}/resume/${applicantData?.application?.resume}`} target="_blank" className="text-blue-500 hover:underline">Download Resume</a> */}
          <div className="flex items-center gap-4 text-xl">
            {/* {applicantData?.fb_url && <Link href={applicantData?.application?.fb_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"><i className="fab fa-facebook-f"></i></Link>} */}
            {applicantData?.linkedin_profile && (
              <a
                href={`${applicantData?.linkedin_profile}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            )}
            {applicantData?.instagram_profile && (
              <a
                href={applicantData?.instagram_profile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                <i className="fab fa-instagram"></i>
              </a>
            )}
            {applicantData?.blog_url && (
              <a
                href={applicantData?.blog_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                <FaGlobe />
              </a>
            )}
            {/* {applicantData?.drive_link && <Link href={applicantData?.drive_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"><i className="fas fa-link"></i></Link>} */}
          </div>
        </div>
      </div>

      {/* <ViewStudentData applicantData={applicantData} /> */}
      {/* <SingleJobApplicationCard applicantData={applicantData} statusArray={statusArray} /> */}
      {/* <div className="bg-white shadow-md rounded-lg overflow-hidden lg:w-1/2 w-full p-6">
        <h2 className="text-xl font-semibold mb-2">Update Application Status</h2>
        <p className="text-gray-600 mb-4"> {statusArray[applicantData?.application?.status]}</p>
        <div className="container mx-auto px-2 py-4">
          <StatusDropdown statusArray={statusArray} onSelect={handleStatusSelect} />
          {selectedStatus && <><textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="resize-y block w-full mt-3 px-3 border border-gray-300 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-transparent"
            style={{ minHeight: '4rem' }}
            rows={3}
            placeholder="Enter Comment........"
          />
            <button onClick={updateApplicationStatus} type="button" className="ml-2 mt-3 px-3 py-2 rounded-md bg-indigo-500 text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
              UPDATE
            </button>
          </>}
        </div>
      </div> */}
    </div>
  );
};

export default SingleApplication;
