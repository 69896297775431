import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useSideBarToggle from "../../hooks/useSideBarToggle";
import useScreenSize from "../../../hooks/useScreenSize";
import useAdminAuth from "../../hooks/useAdminAuth";
import { FaCircle } from "react-icons/fa";
import SidebarSubMenuDropdown from "./SidebarSubMenuDropdown";

function SidebarMenuDropdown({
  link,
  menu,
  index,
  selectedMenu,
  setSelectedMenu,
}) {
  const { sidebarOpen, setSidebarOpen } = useSideBarToggle();
  const isSelectedMenu = menu.submenu.some((sub) => {
    const currentPath = window.location.pathname;
    return (sub.link === '/' && currentPath === '/') || 
           (sub.link !== '/' && currentPath.includes(sub.link));
  });
  


  const { breakpoint } = useScreenSize();
  const { auth, setAuth } = useAdminAuth();
  const [selectedChildIndex, setSelectedChildIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(isSelectedMenu);

  useEffect(() => {
    setMenuOpen(isSelectedMenu);
  }, [isSelectedMenu]);

  const handleSubmenuClick = () => {
    if (breakpoint === "sm") {
      setSidebarOpen(false);
    }
  };

  return auth.roles && auth.roles.includes("Superadmin") ? (
    <div className={`relative font-semibold`}>
      <div
        onClick={() => {
          setMenuOpen(!menuOpen);
          setSelectedMenu(index);
        }}
        className={`flex ${
          isSelectedMenu && "bg-[#0183c0] text-white dark:bg-slate-800"
        } duration-400 cursor-pointer items-center gap-4 whitespace-nowrap rounded-sm p-3 transition ease-in-out hover:bg-[#0183c0] hover:text-white hover:dark:bg-slate-800 hover:dark:text-white ${
          sidebarOpen ? "gap-4" : "justify-center gap-0"
        }`}
      >
        <span
          className={`transition-all duration-100 dark:text-white ${
            selectedMenu === index ? "text-2xl" : "text-xl"
          }`}
        >
          {menu?.icon}
        </span>
        <span
          className={`${
            sidebarOpen ? "" : "hidden"
          } transition-all duration-100 ease-out `}
        >
          {menu?.label}
        </span>
      </div>
      <div
        className={`pl-6 ${menuOpen ? "block" : "hidden"} dark:bg-slate-700`}
      >
        {menu.submenu.map((submenu, subIndex) => {
          if (submenu.submenu) {
            return (
              <div key={subIndex}>
                <SidebarSubMenuDropdown
                  key={subIndex}
                  menu={submenu}
                  index={subIndex}
                  selectedChildIndex={selectedChildIndex}
                  setSelectedChildIndex={setSelectedChildIndex}
                />
              </div>
            );
          } else {
            return (
              <Link
                key={submenu.label}
                to={submenu.link}
                onClick={handleSubmenuClick}
                className={`${
                  submenu.link === window.location.pathname &&
                  "bg-[#D7F2FD] dark:bg-slate-800"
                } flex items-center justify-start gap-2 rounded-sm px-3 py-2 hover:bg-[#D7F2FD]`}
              >
                <FaCircle size={6} /> {submenu.label}
              </Link>
            );
          }
        })}
      </div>
    </div>
  ) : (
    <div className={`relative font-semibold`}>
      <div
        onClick={() => {
          setMenuOpen(!menuOpen);
          setSelectedMenu(index);
        }}
        className={`flex ${
          isSelectedMenu && "bg-[#0183c0] text-white dark:bg-slate-800"
        } duration-400 cursor-pointer items-center gap-4 whitespace-nowrap rounded-sm p-3 transition ease-in-out hover:bg-[#0183c0] hover:text-white hover:dark:bg-slate-800 hover:dark:text-white ${
          sidebarOpen ? "gap-4" : "justify-center gap-0"
        }`}
      >
        <span
          className={`transition-all duration-100 dark:text-white ${
            selectedMenu === index ? "text-2xl" : "text-xl"
          }`}
        >
          {menu?.icon}
        </span>
        <span
          className={`${
            sidebarOpen ? "" : "hidden"
          } transition-all duration-100 ease-out `}
        >
          {menu?.label}
        </span>
      </div>
      <div
        className={`pl-6 ${menuOpen ? "block" : "hidden"} dark:bg-slate-700`}
      >
        {menu.submenu.map((submenu, subIndex) => {
          if (submenu.submenu) {
            const hasMatchingPermission = submenu.parentPermissionNames.some(
              (name) => auth.permissions.includes(name)
            );
            return (
              hasMatchingPermission && (
                <div key={subIndex}>
                  <SidebarSubMenuDropdown
                    key={subIndex}
                    menu={submenu}
                    index={subIndex}
                    selectedChildIndex={selectedChildIndex}
                    setSelectedChildIndex={setSelectedChildIndex}
                  />
                </div>
              )
            );
          } else {
            return (
              auth.permissions.includes(submenu.permissionName) && (
                <Link
                  key={submenu.label}
                  to={submenu.link}
                  onClick={handleSubmenuClick}
                  className={`${
                    submenu.link === window.location.pathname &&
                    "bg-[#D7F2FD] dark:bg-slate-800"
                  } flex items-center justify-start gap-2 rounded-sm px-3 py-2 hover:bg-[#D7F2FD]`}
                >
                  <FaCircle size={6} /> {submenu.label}
                </Link>
              )
            );
          }
        })}
      </div>
    </div>
  );
}

export default SidebarMenuDropdown;
