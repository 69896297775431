import React, { useState } from 'react'
import InputLabel from '../../inputs/InputLabel';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import PhoneNumberInput from '../../../../components/inputFields/PhoneInput';
import { useEffect } from 'react';
import { checkUserExistbyPhone } from '../../../services/CreditNotesServices';
import { toast } from 'react-toastify';
import { swalReact } from '../../../../helpers/Helper';

const GiftCardSenderForm = ({ setUserData }) => {
    const { values, setFieldValue } = useFormikContext();
    const [searchInProgress, setSearchInProgress] = useState(false);

    const handleCheckUserExist = async () => {
        if (searchInProgress) return;
        setSearchInProgress(true);
        if (values?.sender_country_code && values?.sender_phone) {
            try {
                const res = await checkUserExistbyPhone({
                    country_code: values?.sender_country_code,
                    phone: values?.sender_phone
                })
                toast.success(res.data.message)
                if (res.data.data.first_name) {
                    setFieldValue('sender_first_name', res?.data?.data?.first_name)
                    setFieldValue('sender_last_name', res?.data?.data?.last_name)
                    setFieldValue('sender_email', res?.data?.data?.email)
                    setFieldValue('sender_user_id', res?.data?.data?.id)
                } else {
                    // toast.error("User Not Found!")
                    // swalReact({ title: "User Not Found!", text: "If You want to Create a New User Please Fill Personal Details and Submit..", showConfirmButton: true, confirmButtonText: true })
                    setFieldValue('sender_first_name', "")
                    setFieldValue('sender_last_name', "")
                    setFieldValue('sender_email', "")
                    setFieldValue('sender_user_id', null)
                }
            } catch (error) {
                console.log(error)
            }
        }
        else {
            toast.error("type a valid phone number")
        }
        setSearchInProgress(false);
    }

    return (
        <div className="my-4 grow">
            <div className="flex flex-wrap gap-4">
                {/* Phone Number */}
                <div className="grow basis-[45%] lg:max-w-[50%]">
                    <PhoneNumberInput
                        label="Phone Number"
                        onBlur={handleCheckUserExist}
                        phone={`+${values.sender_country_code} ${values.sender_phone}`}
                        onChange={(value, data) => {
                            if (value.slice(data.dialCode.length) !== values?.sender_phone) {
                                setFieldValue(`sender_country_code`, data.dialCode);
                                setFieldValue(
                                    `sender_phone`,
                                    value.slice(data.dialCode.length)
                                );
                            }
                        }}
                    />
                    <ErrorMessage name="sender_country_code">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                    <ErrorMessage name="sender_phone">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>
                <div className="flex justify-center items-end">
                    <button
                        onClick={handleCheckUserExist}
                        className="bg-[#0183c0] text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-200"
                    >
                        Search
                    </button>
                </div>

                {/* //? First Name */}
                <div className="grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="First Name" labelFor="sender_first_name" required={true} />
                    <Field
                        className="field"
                        id="sender_first_name"
                        name="sender_first_name"
                    />
                    <ErrorMessage name="sender_first_name">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>

                {/* //? Last Name */}
                <div className="grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Last Name" labelFor="sender_last_name" required={true} />
                    <Field
                        className="field"
                        id="sender_last_name"
                        name="sender_last_name"
                    />
                    <ErrorMessage name="sender_last_name">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>

                {/* //? Email */}
                <div className="grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Email" labelFor="sender_email" required={true} />
                    <Field
                        className="field"
                        id="sender_email"
                        name="sender_email"
                    />
                    <ErrorMessage name="sender_email">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>
            </div>
        </div>
    )
}

export default GiftCardSenderForm