import { useState, useEffect } from "react";
import {
  FaEnvelope,
  FaPlusSquare,
  FaRupeeSign,
  FaWhatsapp,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import {
  formatNumberToTwoDigit,
  getReadableDate,
  getReadableDateAndTime,
} from "../../helpers/Helper";
import { getUploadsUrl } from "../../ServiceConfig";
import {
  checkUserIdMismatchBookingById,
  getBookingById,
  mailRemainingPaymentNotification,
  matchUserByBookingId,
  resendPaymentReceipt,
  resendInvoiceByInvoiceId,
  sendUpdateParticipantNotification,
  regenerateInvoiceByInvoiceId,
  generateInvoiceByBookingIdService,
  checkRazorPayStatusByOrderIdAndUpdate,
} from "../services/BookingServices";
import { getAllEmployeesOption } from "../services/EmployeeServices";
import { getAllOccupancies } from "../services/PackageService";
import { getAllRidersOption } from "../services/RiderServices";
// import { getAllStateByCountry } from "../services/StateServices";
import { getAllTravelModesOption } from "../services/TravelModeServices";
import UpdateParticipants from "./UpdateParticipants";

import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import UpdatePaymentReciept from "./UpdatePaymentReciept";
import moment from "moment";
import DataTable from "../components/common/DataTable";
import { toast } from "react-toastify";
import BookingCancellationForm from "./BookingForms/BookingCancellationForm";
import NotAuthorized403 from "../components/NotAuthorized403";
import useAdminAuth from "../hooks/useAdminAuth";
import useLoader from "../../hooks/useLoader";
import Loader from "../../components/Loader";
import { getAllStateOption } from "../services/StateServices";

Modal.setAppElement("#modal");

function ViewBooking() {
  const bookingId = useParams().id;
  const { auth } = useAdminAuth();

  const [bookingData, setBookingData] = useState();
  // const [states, setStates] = useState();
  const [travelModes, setTravelModes] = useState();
  const [riders, setRiders] = useState();
  const [occupancies, setOccupancies] = useState();
  const [employees, setEmployees] = useState();
  const [participantModalIsOpen, setParticipantModalIsOpen] = useState(false);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [cancellationModal, setCancellationModal] = useState(false);
  const [isUserIdMismatch, setIsUserIdMismatch] = useState(false);
  const [shouldBeUserData, setShouldBeUserData] = useState(false);

  const [recieptToUpdate, setRecieptToUpdate] = useState();
  const [activeParticipantData, setActiveParticipantData] = useState();
  const [particpantIdModalIsOpen, setParticpantIdModalIsOpen] = useState(false);
  const [stateGst, setStateGst] = useState([]);
  const { setLoading } = useLoader();

  const getBookingData = () => {
    getBookingById(bookingId).then((res) => {
      setBookingData(res.data.data);
    });
    checkUserIdMismatchBookingById(bookingId).then((res) => {
      setIsUserIdMismatch(res.data?.is_mismatch);
      setShouldBeUserData(res.data?.should_be_user_data);

      console.log(res.data, "To check if user id mismatch");
    });
  };

  useEffect(() => {
    getAllStateOption().then((res) => {
      setStateGst(res.data.data);
    });
  }, []);

  const matchUserOfBooking = (bookingid) => {
    if (
      window.confirm(
        "Are you sure, you want to update user info based on the booking data??"
      ) == true
    ) {
      // alert(bookingid);
      matchUserByBookingId(bookingid).then((res) => {
        window.location.reload();
      });
    } else {
    }
  };

  useEffect(() => {
    getBookingData();
  }, [bookingId]);

  useEffect(() => {
    getAllTravelModesOption().then((res) => {
      setTravelModes(res.data.data);
    });

    getAllRidersOption().then((res) => {
      setRiders(res.data.data);
    });

    getAllOccupancies().then((res) => {
      setOccupancies(res.data.data);
    });

    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });
  }, []);

  // useEffect(() => {
  //   getAllStateByCountry(bookingData?.country_id).then((res) => {
  //     setStates(res.data.data);
  //   });
  // }, [bookingData]);

  useEffect(() => {
    recieptToUpdate && setPaymentModalIsOpen(true);
  }, [recieptToUpdate]);

  // const getEmployeeName = (empId) => {
  //   let name = "";
  //   let emp = employees?.filter((emp) => emp.id === empId);
  //   name = `${emp?.[0]?.first_name} ${emp?.[0]?.last_name}`;
  //   return name;
  // };

  const getUpdatedData = () => {
    setLoading(true);
    getBookingById(bookingId).then((res) => {
      setBookingData(res.data.data);
      setLoading(false);
    });
  };

  const getRiderName = (riderId) => {
    let riderName = riders?.filter((rider) => rider.id === riderId)[0]?.name;

    if (riderName) {
      return riderName;
    } else {
      return "N/A";
    }
  };

  const mailPaymentNotification = () => {
    setLoading(true);
    mailRemainingPaymentNotification(bookingId).then((res) => {
      toast.success(res.data.data);
      setLoading(false);
    });
  };

  const resendReceipt = (id) => {
    setLoading(true);
    resendPaymentReceipt(id)
      .then((res) => {
        toast.success(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };
  const checkRazorPayStatusByOrderId = (id) => {
    setLoading(true);
    checkRazorPayStatusByOrderIdAndUpdate({ order_id: id })
      .then((res) => {
        // console.log(res.data.message);
        toast.success(res.data.message);
        getUpdatedData();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };
  const resendInvoice = (id) => {
    resendInvoiceByInvoiceId(id)
      .then((res) => {
        toast.success(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const regenerateInvoice = (id) => {
    regenerateInvoiceByInvoiceId(id)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const generateInvoiceByBookingId = (id) => {
    generateInvoiceByBookingIdService(id)
      .then((res) => {
        getUpdatedData();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const UpdateParticipantsNotification = () => {
    sendUpdateParticipantNotification(bookingId)
      .then((res) => {
        toast.success(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  // const getTotalAddonCost = () => {
  //   let addonCost = 0;
  //   if (bookingData?.addons?.length > 0) {
  //     bookingData?.addons?.forEach((addon) => {
  //       addonCost += addon.amount;
  //     });

  //     return addonCost;
  //   } else {
  //     return 0;
  //   }
  // };

  const onCancelBooking = () => {
    getBookingData();
  };

  useEffect(() => {
    activeParticipantData && setParticpantIdModalIsOpen(true);
  }, [activeParticipantData]);

  const actionIcons = function (cell, formatterParams, onRendered) {
    if (cell.getRow().getData().status !== 0) {
      return `<div class="resend-declaration-btn" data-id=${
        cell.getRow().getData().id
      } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_901_1388)">
<path d="M22.0002 12L13.0002 22V31L16.0002 27M31.0002 1L1.00019 17L25.0002 27L31.0002 1Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_901_1388">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg></div>
   `;
    } else {
      return "";
    }
  };

  const columns = [
    // {
    //   formatter: "responsiveCollapse",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "",
      field: "id",
      responsive: 0,
      minWidth: 50,
      resizable: true,
      vertAlign: "middle",
    },
    // {
    //   title: "Membership",
    //   field: "package_name",
    //   minWidth: "100",
    //   vertAlign: "middle",
    //   responsive: 0,
    //   sorterParams: {
    //     locale: true,
    //     alignEmptyValues: "top",
    //   },
    //   resizable: true,
    // },
    {
      title: "Name",
      frozen: true,
      minWidth: 150,
      field: "event_date",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().first_name ||
          cell.getRow().getData().last_name
        ) {
          return `${
            cell.getRow().getData().first_name
              ? cell.getRow().getData().first_name
              : ""
          } ${
            cell.getRow().getData().last_name
              ? cell.getRow().getData().last_name
              : ""
          }`;
        } else {
          return "";
        }
      },
    },
    {
      title: "Email",
      minWidth: 150,
      field: "email",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Phone",
      field: "phone",
      minWidth: 100,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Whatsapp",
      field: "whatsapp",
      resizable: true,
      minWidth: 100,
      vertAlign: "middle",
    },
    {
      title: "Age",
      field: "age",
      minWidth: 50,
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Gender",
      field: "gender",
      minWidth: 80,
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Travel Mode",
      field: "travel_mode_name",
      minWidth: 120,
      resizable: true,
      vertAlign: "middle",
      // formatter: function (cell, formatterParams, onRendered) {
      //   return travelModes?.filter(
      //     (tm) => tm.id === cell.getRow().getData().travel_mode_id
      //   )[0]?.name;
      // },
    },
    {
      title: "Rider",
      field: "rider_name",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      // formatter: function (cell, formatterParams, onRendered) {
      //   return occupancies?.filter(
      //     (occ) => occ.id === cell.getRow().getData().occupancy_id
      //   )[0]?.name;
      // },
    },
    {
      title: "Occupancy",
      field: "occupancy_name",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      // formatter: function (cell, formatterParams, onRendered) {
      //   return occupancies?.filter(
      //     (occ) => occ.id === cell.getRow().getData().occupancy_id
      //   )[0]?.name;
      // },
    },
    {
      title: "Eme. Contact",
      field: "emergency_contact",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Eme. Relation",
      field: "emergency_contact_relation",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Eme. Name",
      field: "emergency_contact_name",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Coming From",
      field: "coming_from",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "ID Type",
      field: "id_proof",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Id Attachment",
      field: "id_attachment",
      resizable: true,
      vertAlign: "middle",
      minWidth: 100,
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getRow().getData().id_attachment != "") {
          if (cell.getRow().getData().id_attachment.split(".").pop() == "pdf") {
            return `<a href="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a href="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }" ><img src="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
      formatterParams: {
        height: "50px",
        width: "80px",
        // urlPrefix: `${getUploadsUrl()}/bookings/participant_id_attachment/`,
      },
    },
    {
      title: "Medical Certificate",
      field: "medical_certificate",
      resizable: true,
      minWidth: 100,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getRow().getData().medical_certificate != "") {
          if (
            cell.getRow().getData().medical_certificate.split(".").pop() ==
            "pdf"
          ) {
            return `<a href="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a href="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }" ><img src="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
      formatterParams: {
        height: "50px",
        width: "80px",
        // urlPrefix: `${getUploadsUrl()}/bookings/participant_id_attachment/`,
      },
    },
    // {
    //   title: "Resend Declaration",
    //   frozen: true,
    //   formatter: actionIcons,
    //   headerSort: false,
    //   width: 80,
    //   responsive: 0,
    //   hozAlign: "center",
    //   vertAlign: "middle",
    // },
  ];

  const rowFormatter = (row) => {
    if (row.getData().status === 0) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#D83F31";
      });
    }
  };

  return !auth?.permissions?.includes("Booking.read") &&
    !auth?.roles?.includes("Superadmin") ? (
    <NotAuthorized403 />
  ) : !bookingData ? (
    <Loader />
  ) : (
    <div className="lg:p-4">
      <div className="my-4 flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center">
        <div className="flex items-center gap-4">
          <h2
            className={`text-xl font-bold ${
              bookingData?.cancellation?.cancellation_type
                ? "text-[#D83F31]"
                : ""
            }`}
          >
            {bookingData?.is_customized ? "Customized" : ""} Booking : #
            {bookingData?.id}
          </h2>

          {bookingData?.cancellation?.cancellation_type && (
            <div className="rounded-full bg-[#D83F31] px-2 py-1 capitalize text-white">
              {bookingData?.cancellation?.cancellation_type} Cancellation
            </div>
          )}
        </div>
        {bookingData?.cancellation_type !== 1 && (
          <div className="mt-4 flex flex-row gap-2 lg:mt-0 lg:flex-row lg:gap-4 ">
            {(auth?.permissions?.includes("Booking.cancellation") ||
              auth?.roles?.includes("Superadmin")) && (
              <button
                onClick={() => setCancellationModal(true)}
                className="btn-primary bg-red-500"
              >
                Cancel
              </button>
            )}
            {(auth?.permissions?.includes("Booking.update") ||
              auth?.roles?.includes("Superadmin")) && (
              <Link
                to={`/bookings/edit/${bookingData?.id}`}
                className="btn-primary"
              >
                Edit
              </Link>
            )}
          </div>
        )}
      </div>

      <div className="rounded-lg border-2 bg-white p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Billing Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Name : </b> {bookingData?.first_name} {bookingData?.last_name}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Email : </b> {bookingData?.email}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Phone : </b> +{bookingData?.country_code} - {bookingData?.phone}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Company : </b> {bookingData?.firm_name}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>GST Number : </b> {bookingData?.gst_number}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Address : </b> {bookingData?.firm_address}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>State : </b>
            {bookingData?.gst_state_name}
            {/* {
              stateGst?.filter((state) => state.gst_code === bookingData.gst_state)[0]?.name
            } */}
          </div>
        </div>
        <div className="my-4 flex flex-wrap items-center">
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Currently Attached User Id : </b> {bookingData?.user_id}
          </div>
          {isUserIdMismatch && (
            <>
              <div className="my-2 basis-full lg:basis-1/3">
                <b>Should be Attached User Id : </b>{" "}
                {shouldBeUserData?.id || "No User Found"}
              </div>
              <div className="my-2 basis-full lg:basis-1/3">
                <button
                  className="rounded-lg border-2 bg-green-600 py-2 px-4 font-bold text-white"
                  onClick={() => matchUserOfBooking(bookingData?.id)}
                >
                  Match Now
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Package Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div
            className={
              bookingData?.is_customized
                ? "hidden"
                : "my-2 basis-full lg:basis-1/3"
            }
          >
            <b>Package Id: </b> {bookingData?.package_id}
          </div>
          <div className="my-2 grow basis-full lg:basis-2/3">
            <b>Package : </b> {bookingData?.package_name}
          </div>

          <div className="my-2 basis-full lg:basis-1/3">
            <b>Event Date : </b> {getReadableDate(bookingData?.event_date)}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Pick Drop Location : </b> {bookingData?.starting_location}{" "}
            &#8594; {bookingData?.ending_location}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Number of days : </b> {bookingData?.no_of_days}{" "}
          </div>
          <div className="my-2 basis-full">
            <b>Special Notes : </b>
            <div
              dangerouslySetInnerHTML={{
                __html: `${bookingData?.special_notes || ""}`,
              }}
            ></div>
          </div>
          {/* <div className="my-2 basis-full">
            <b>Custom Itinerary : </b>
          </div> */}
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <h2 className="text-xl font-bold">Cost Calculation</h2>
        <div className="mb-4 overflow-x-auto">
          <table className="w-full table-auto text-center	">
            <thead>
              <tr className="border-b bg-gray-200">
                <th className="py-2">Travel Mode</th>
                <th className="py-2">Rider</th>
                <th className="py-2">Occupancy</th>
                <th className="py-2">Price</th>
                <th className="py-2">Discount</th>
                <th className="py-2">Final</th>
                <th className="py-2">GST</th>
                <th className="py-2">With GST</th>
                <th className="py-2">Wraveler</th>
                <th className="py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              {bookingData?.cost?.map((booking, index) => {
                return (
                  <tr
                    key={booking.id}
                    className={`border-b ${
                      index % 2 === 0 ? "" : "bg-gray-50"
                    }`}
                  >
                    <td className="py-2">
                      {
                        travelModes?.filter(
                          (tm) => tm.id === parseInt(booking?.travel_mode_id)
                        )[0]?.name
                      }
                    </td>
                    <td className="py-2">{getRiderName(booking?.rider_id)}</td>
                    <td className="py-2">
                      {
                        occupancies?.filter(
                          (occ) => occ.id === parseInt(booking?.occupancy_id)
                        )[0]?.name
                      }
                    </td>
                    <td className="py-2">{booking?.package_price}</td>
                    <td className="py-2">{booking?.package_discount}</td>
                    <td className="py-2">
                      {booking?.package_price_after_discount}
                    </td>
                    <td className="py-2">{booking?.package_gst}</td>
                    <td className="py-2">{booking?.package_with_gst}</td>
                    <td className="py-2">{booking?.pax}</td>
                    <td className="py-2">{booking?.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {bookingData?.addons?.length > 0 && (
        <div className="my-4 rounded-lg border-2 bg-white p-4">
          <h2 className="text-xl font-bold">Addons</h2>
          <div className="mb-4">
            <table className="w-full text-center">
              <thead>
                <tr className="border-b bg-gray-200">
                  <th className="py-2">Title</th>
                  <th className="py-2">Description</th>
                  <th className="py-2">Unit Type</th>
                  <th className="py-2">Unit Price</th>
                  <th className="py-2">Unit GST</th>
                  <th className="py-2">Unit</th>
                  <th className="py-2">Amount</th>
                </tr>
              </thead>
              <tbody>
                {bookingData?.addons?.map((addon, index) => {
                  return (
                    <tr
                      key={addon.id}
                      className={`border-b ${
                        index % 2 === 0 ? "" : "bg-gray-50"
                      }`}
                    >
                      <td className="py-2">{addon?.title}</td>
                      <td className="py-2">{addon?.description}</td>
                      <td className="py-2">{addon?.unit_type}</td>
                      <td className="py-2">{addon?.unit_price}</td>
                      <td className="py-2">{addon?.unit_gst}</td>
                      <td className="py-2">{addon?.unit}</td>
                      <td className="py-2">{addon?.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="lg:flex-column flex flex-col items-end justify-between gap-4 pb-4">
        <div className="text-right">
          <table className="w-full">
            <tbody>
              <tr>
                <td className="font-semibold">Total Pax :</td>
                <td className="px-4 py-1 font-bold">
                  {formatNumberToTwoDigit(bookingData?.total_pax)}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total Price :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_cost}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">
                  Total Discount{" "}
                  {bookingData.is_coupon_applied
                    ? `(Coupon Code - ${bookingData.coupon_code})`
                    : ""}{" "}
                  {bookingData.is_membership_used ? `(Membership Applied)` : ""}{" "}
                  :{" "}
                </td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_discount}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total Cost After Discount :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_cost_after_discount}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total GST :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_gst}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total Amount after GST :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign />{" "}
                  {bookingData?.total_gst +
                    bookingData?.total_cost_after_discount}
                </td>
              </tr>
              {bookingData?.total_tcs > 0 && (
                <tr>
                  <td className="font-semibold">Total TCS :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.total_tcs}
                  </td>
                </tr>
              )}
              {bookingData.amount_used_in_gift_card > 0 && (
                <tr>
                  <td className="font-semibold">Gift Card Redeemed :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_used_in_gift_card}
                  </td>
                </tr>
              )}
              {bookingData.amount_used_in_credit_note > 0 && (
                <tr>
                  <td className="font-semibold">Credit Note Used :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_used_in_credit_note}
                  </td>
                </tr>
              )}
              <tr>
                <td className="font-semibold">Amount to Pay :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_amount_to_pay}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Amount Paid :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_amount_paid}
                </td>
              </tr>
              {bookingData?.amount_deducted > 0 && (
                <tr>
                  <td className="font-semibold">Amount Deducted :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_deducted}
                  </td>
                </tr>
              )}
              {bookingData?.credit_note > 0 && (
                <tr>
                  <td className="font-semibold">Refunded as Credit Note :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.credit_note}
                  </td>
                </tr>
              )}
              {bookingData?.refund_amount > 0 && (
                <tr>
                  <td className="font-semibold">Amount Deducted :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.refund_amount}
                  </td>
                </tr>
              )}
              <tr>
                <td className="font-semibold">Remaining Amount :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.remaining_amount}
                </td>
              </tr>
            </tbody>
          </table>
          {bookingData?.remaining_amount > 0 &&
            bookingData?.cancellation?.cancellation_type !== "full" &&
            (auth?.permissions?.includes("Booking.addRemainingPayment") ||
              auth?.roles?.includes("Superadmin")) && (
              <Link
                to={`/bookings/balancePayment/${bookingData?.id}`}
                className="mt-2 flex items-center justify-center gap-1 rounded-lg border border-jw-green bg-white px-4 py-2 text-jw-green"
              >
                <FaPlusSquare /> Add Payments
              </Link>
            )}
          {bookingData?.remaining_amount > 0 &&
            bookingData?.cancellation?.cancellation_type !== "full" &&
            (auth?.permissions?.includes(
              "PaymentVerification.remaingPayment.create"
            ) ||
              auth?.roles?.includes("Superadmin")) && (
              <Link
                to={`/bookings/remaining-payment-verification/add/${bookingData?.id}`}
                className="mt-2 flex items-center justify-center gap-1 rounded-lg border border-jw-green bg-white px-4 py-2 text-jw-green"
              >
                <FaPlusSquare /> Add Remaining Payment Screenshot
              </Link>
            )}
        </div>
        <div className="w-full lg:ml-4 lg:w-auto">
          {bookingData?.cancellation?.cancellation_type !== "full" && (
            <div className="mt-4 text-center lg:mt-0">
              <span className="font-bold">
                Send Remaining Payment Notification
              </span>
              <div className="mt-2 flex flex-row items-center justify-center gap-2 lg:flex-row">
                <button
                  onClick={mailPaymentNotification}
                  className="flex items-center justify-center gap-1 rounded-lg bg-blue-500 py-2 px-4 text-white"
                >
                  <FaEnvelope /> Mail
                </button>
                <a
                  target="_blank"
                  href={`https://api.whatsapp.com/send?phone=${
                    bookingData?.country_code || 91
                  }${bookingData?.phone}&text=${encodeURIComponent(`Hello ${
                    bookingData?.first_name
                  } ${bookingData?.last_name}
            
This is *${auth.first_name} ${
                    auth.last_name
                  }* from  *JustWravel* - \#wravelerforlife Travel Community.

I hope you are all packed and excited for your upcoming *${
                    bookingData?.package_name
                  }* , departing on *${getReadableDate(
                    moment(bookingData?.event_date, "YYYY-MM-DD")
                      .add(
                        bookingData?.package?.itineraries[0]
                          ?.departure_option ?? 0,
                        "days"
                      )
                      .format()
                  )}* from *${bookingData?.starting_location} to ${
                    bookingData?.ending_location
                  }*.

This is a reminder message for clearing the *remaining amount of INR ${
                    bookingData?.remaining_amount
                  } due* in respect of BookingId #${bookingData?.id}.

Please make the payment at the earliest in any of the below mentioned modes of payment-

*Account Details* ::

Name: JUSTWRAVEL PVT LTD
Account No: 158005500775
Account Type: Current 
IFSC code: ICIC0001580


Name: JUSTWRAVEL PVT LTD
Bank: HDFC
Account No: 50200062275612
Account Type: Current
IFSC Code: HDFC0002649


*Website link* : https://www.justwravel.com/user/bookings/${bookingData?.id}

*UPI ID* :

> For Android Users:
upi://pay?pa=MSJUSTWRAVELPRIVATELIMITED.eazypay@icici (* Accepts All UPI App except PhonePe).

> For iPhone Users:
MSJUSTWRAVELPRIVATELIMITED.eazypay@icici

> justwravel@upi

> 8586842663 (GPay, PhonePe, Paytm)


 Also , do share the screenshot once the payment is done, I will get the payment receipt updated and that will help us ease the operations.

Have a Safe Journey.

Happy Wraveling.
															`)}`}
                  className="flex items-center justify-center gap-1 rounded-lg bg-green-500 py-2 px-4 text-white"
                >
                  <FaWhatsapp /> Whatsapp
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      {bookingData?.invoices?.length > 0 ? (
        <div className="mt-12">
          {bookingData?.invoices.map((invoice) => {
            return (
              <div key={invoice.id} className={`my-4 rounded-lg border-2 p-4 `}>
                <div className="flex items-center justify-between border-b-2 pb-4">
                  <h2 className=" text-xl font-bold">
                    Invoice Detail : #{invoice?.id}
                  </h2>
                </div>

                <div className="my-4 flex flex-wrap items-center">
                  <div className="my-2 basis-full lg:basis-1/3">
                    <b>Invoice Id :</b> {invoice?.id}
                  </div>
                  <div className="my-2 basis-full lg:basis-1/3">
                    <b>Generated at :</b> {getReadableDate(invoice?.created_at)}
                  </div>
                  <div className="my-2 basis-full lg:basis-1/3">
                    <b>Sent Status : </b> {invoice?.id}
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <a
                    href={`${getUploadsUrl()}/booking/pdf/invoice/invoice-${
                      invoice?.id
                    }.pdf`}
                    target="_blank"
                    className="rounded-lg bg-blue-500 py-2 px-16 text-white"
                  >
                    View Invoice
                  </a>

                  <button
                    onClick={() => regenerateInvoice(invoice.id)}
                    className="rounded-lg bg-green-600 py-2 px-16 text-white"
                  >
                    Re-Generate Invoice
                  </button>
                  <button
                    onClick={() => resendInvoice(invoice.id)}
                    className="rounded-lg bg-purple-500 py-2 px-16 text-white"
                  >
                    Resend Invoice
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-row items-center justify-center">
          <button
            onClick={() => generateInvoiceByBookingId(bookingData.id)}
            className="rounded-lg bg-green-600 py-2 px-16 text-white"
          >
            Generate Invoice
          </button>
        </div>
      )}

      {bookingData?.cancellation?.length > 0 && (
        <div className="mt-12">
          <h2 className=" text-xl font-bold text-[#D83F31]">Refund Detail</h2>
          {bookingData?.cancellation?.map((cancel) => (
            <div key={cancel.id} className="my-4">
              <div className="rounded-lg border-2 border-[#D83F3120] bg-white">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="py-1 text-gray-500">Paid Amount</th>
                      <th className="text-[#3876BF]">Amount Deducted</th>
                      <th className="text-[#D83F31]">Refund Amount</th>
                      <th className="text-[#D83F31]">Credit Note</th>
                      <th
                        className={`${
                          cancel?.refund_status === 0 && "text-[#9E6F21]"
                        } ${cancel?.refund_status === 1 && "text-green-600"} ${
                          cancel?.refund_status === 2 && "text-[#D83F31]"
                        } `}
                      >
                        Refund Status
                      </th>
                      <th>Transaction Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td className="py-1 text-gray-500">
                        {bookingData?.total_amount_paid}
                      </td>
                      <td className="capitalize text-[#3876BF]">
                        {cancel?.amount_deducted}
                      </td>
                      <td className="text-[#D83F31]">
                        {cancel?.refund_amount}
                      </td>
                      <td className="text-[#D83F31]">{cancel?.credit_note}</td>
                      <td
                        className={`${
                          cancel?.refund_status === 0 && "text-[#9E6F21]"
                        } ${cancel?.refund_status === 1 && "text-green-600"} ${
                          cancel?.refund_status === 2 && "text-[#D83F31]"
                        }`}
                      >
                        {cancel?.refund_status === 0 && "Initiated"}
                        {cancel?.refund_status === 1 && "Completed"}
                        {cancel?.refund_status === 2 && "Failed"}
                      </td>
                      <td>{cancel?.transaction_id}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="mt-12">
        {bookingData?.payment.map((payment) => {
          return (
            <div
              key={payment.id}
              className={`my-4 rounded-lg border-2 p-4 ${
                payment?.transaction_verification_status === 0
                  ? "bg-red-300"
                  : "bg-green-100"
              }`}
            >
              <div className="flex flex-col items-start justify-between border-b-2 pb-4 lg:flex-row lg:items-center">
                <h2 className="text-xl font-bold">
                  Payment Detail : #{payment?.id}
                </h2>

                {payment?.transaction_verification_status === 0 && (
                  <p className="text-bold text-red-700">
                    Payment Status: Pending
                  </p>
                )}
                {payment?.transaction_verification_status == 1 && (
                  <p className="text-bold text-green-700">
                    Payment Status: Success
                  </p>
                )}

                {bookingData?.cancellation?.cancellation_type !== "full" &&
                  payment?.transaction_verification_status == 1 &&
                  (auth?.permissions?.includes("Booking.updatePayment") ||
                    auth?.roles?.includes("Superadmin")) && (
                    <button
                      onClick={() => {
                        setRecieptToUpdate(payment);
                      }}
                      className="mt-2 rounded-lg bg-green-500 py-2 px-4 text-white lg:mt-0"
                    >
                      Update Receipt
                    </button>
                  )}
                {bookingData?.cancellation?.cancellation_type !== "full" &&
                  payment?.transaction_verification_status == 0 &&
                  payment?.payment_method == "razorpay" &&
                  payment?.order_id !== null && (
                    <button
                      onClick={() => {
                        checkRazorPayStatusByOrderId(payment?.order_id);
                      }}
                      className="mt-2 rounded-lg bg-green-500 py-2 px-4 text-white lg:mt-0"
                    >
                      Check Razorpay Status
                    </button>
                  )}
              </div>

              <div className="my-4 flex flex-wrap items-center">
                <div className="my-2 w-full lg:w-1/3">
                  <b>Payment Receipt Id :</b> {payment?.id}
                </div>
                <div className="my-2 w-full lg:w-1/3">
                  <b>Payment Date :</b> {getReadableDate(payment?.created_at)}
                </div>
                <div className="my-2 w-full lg:w-1/3">
                  <b>Payment Method : </b> {payment?.payment_method}
                </div>
                <div className="my-2 w-full lg:w-1/3">
                  <b>Transaction Id : </b> {payment?.transaction_id}
                </div>
                <div className="my-2 w-full lg:w-1/3">
                  <b>Amount Received : </b> {payment?.amount}
                </div>
                <div className="my-2 w-full">
                  <b>Created By : </b>
                  {payment?.created_by ? (
                    <span>
                      {payment?.created_by?.first_name}{" "}
                      {payment?.created_by?.last_name}
                    </span>
                  ) : bookingData?.created_by ? (
                    <span>
                      {bookingData?.created_by?.first_name}{" "}
                      {bookingData?.created_by?.last_name}
                    </span>
                  ) : (
                    <span>Website</span>
                  )}
                </div>
                <div className="my-2 w-full">
                  <b>Lead Converted By : </b>
                  {bookingData?.converted_by ? (
                    <span>
                      {bookingData?.converted_by?.first_name}{" "}
                      {bookingData?.converted_by?.last_name}
                    </span>
                  ) : (
                    <span>Website</span>
                  )}
                </div>
                {/* <div className="my-2 w-full">
                  <b>Comments By User : </b>
                </div>
                <div className="my-2 w-full">
                  <b>Comments By Team : </b>
                  {bookingData?.comment && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${
                          bookingData?.comment?.comment || bookingData?.comment
                        } at ${bookingData?.comment?.time || ""}`,
                      }}
                    ></span>
                  )}
                </div> */}
              </div>

              {payment?.transaction_verification_status == 1 && (
                <div className="flex flex-col items-center gap-4 lg:flex-row">
                  <a
                    href={`${getUploadsUrl()}/booking/pdf/payment-receipt/payment-receipt-${
                      payment?.id
                    }.pdf?v=${Date.now()}`}
                    target="_blank"
                    className="w-full rounded-lg bg-blue-500 py-2 px-4 text-center text-white lg:w-1/2"
                  >
                    View Payment Receipt
                  </a>
                  {bookingData?.cancellation?.cancellation_type !== "full" && (
                    <button
                      onClick={() => resendReceipt(payment.id)}
                      className="w-full rounded-lg bg-purple-500 py-2 px-4 text-center text-white lg:w-1/2"
                    >
                      Resend Payment Receipt
                    </button>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* <div className="mt-12">
        {bookingData?.payment.map((payment) => {
          return (
            <div
              key={payment.id}
              className={`my-4 rounded-lg border-2 p-4 ${
                payment?.transaction_verification_status === 0
                  ? "bg-red-300"
                  : "bg-green-100"
              }`}
            >
              <div className="flex items-center justify-between border-b-2 pb-4">
                <h2 className=" text-xl font-bold">
                  Payment Detail : #{payment?.id}
                </h2>

                {payment?.transaction_verification_status === 0 && (
                  <p className="text-bold text-red-700">
                    Payment Status: Pending
                  </p>
                )}
                {payment?.transaction_verification_status == 1 && (
                  <p className="text-bold text-green-700">
                    Payment Status: Success
                  </p>
                )}

                {bookingData?.cancellation?.cancellation_type !== "full" &&
                  payment?.transaction_verification_status == 1 &&
                  (auth?.permissions?.includes("Booking.updatePayment") ||
                    auth?.roles?.includes("Superadmin")) && (
                    <button
                      onClick={() => {
                        setRecieptToUpdate(payment);
                      }}
                      className="rounded-lg bg-green-500 py-2 px-4 text-white"
                    >
                      Update Receipt
                    </button>
                  )}
                {bookingData?.cancellation?.cancellation_type !== "full" &&
                  payment?.transaction_verification_status == 0 &&
                  payment?.payment_method == "razorpay" &&
                  payment?.order_id != null && (
                    <button
                      onClick={() => {
                        checkRazorPayStatusByOrderId(payment?.order_id);
                      }}
                      className="rounded-lg bg-green-500 py-2 px-4 text-white"
                    >
                      Check Razorpay Status
                    </button>
                  )}
              </div>

              <div className="my-4 flex flex-wrap items-center">
                <div className="my-2 basis-full lg:basis-1/3">
                  <b>Payment Receipt Id :</b> {payment?.id}
                </div>
                <div className="my-2 basis-full lg:basis-1/3">
                  <b>Payment Date :</b> {getReadableDate(payment?.created_at)}
                </div>
                <div className="my-2 basis-full lg:basis-1/3">
                  <b>Payment Method : </b> {payment?.payment_method}
                </div>
                <div className="my-2 basis-full lg:basis-1/3">
                  <b>Transaction Id : </b> {payment?.transaction_id}
                </div>
                <div className="my-2 grow basis-1/3">
                  <b>Amount Received : </b> {payment?.amount}
                </div>
                <div className="my-2 basis-full">
                  <b>Created By : </b>
                  {bookingData?.created_by ? (
                    <span>
                      {bookingData?.created_by?.first_name}{" "}
                      {bookingData?.created_by?.last_name}
                    </span>
                  ) : (
                    <span>Website</span>
                  )}
                </div>
                <div className="my-2 basis-full">
                  <b>Lead Converted By : </b>
                  {bookingData?.converted_by ? (
                    <span>
                      {bookingData?.converted_by?.first_name}{" "}
                      {bookingData?.converted_by?.last_name}
                    </span>
                  ) : (
                    <span>Website</span>
                  )}
                </div>
                <div className="my-2 basis-full">
                  <b>Comments By User : </b>
                </div>
                <div className="my-2 basis-full">
                  <b>Comments By Team : </b>
                  {bookingData?.comment && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${
                          bookingData?.comment?.comment || bookingData?.comment
                        } at ${bookingData?.comment?.time || ""}`,
                      }}
                    ></span>
                  )}
                </div>
              </div>

              {payment?.transaction_verification_status == 1 && (
                <div className="flex items-center gap-4">
                  <a
                    href={`${getUploadsUrl()}/booking/pdf/payment-receipt/payment-receipt-${
                      payment?.id
                    }.pdf?v=${Date.now()}`}
                    target="_blank"
                    className="basis-full rounded-lg bg-blue-500 py-2 px-16 text-white lg:basis-1/2"
                  >
                    View Payment Receipt
                  </a>
                  {bookingData?.cancellation?.cancellation_type !== "full" && (
                    <button
                      onClick={() => resendReceipt(payment.id)}
                      className="lg:basos-1/2 basis-full rounded-lg bg-purple-500 py-2 px-16 text-white"
                    >
                      Resend Payment Receipt
                    </button>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div> */}

      <div className="mt-12 bg-white">
        <h3 className="p-4 font-bold text-black">Comments:</h3>
        <div className="overflow-x-auto">
          <table className="w-full table-auto border-collapse p-8 text-sm">
            <thead>
              <tr>
                <th className="border-b p-4 pl-8 pt-0 pb-3 text-left font-medium text-slate-400 dark:border-slate-600 dark:text-slate-200">
                  #
                </th>
                <th className="border-b p-4 pl-8 pt-0 pb-3 text-left font-medium text-slate-400 dark:border-slate-600 dark:text-slate-200">
                  comment
                </th>
                <th className="border-b p-4 pl-8 pt-0 pb-3 text-left font-medium text-slate-400 dark:border-slate-600 dark:text-slate-200">
                  Updated By
                </th>
                <th className="border-b p-4 pl-8 pt-0 pb-3 text-left font-medium text-slate-400 dark:border-slate-600 dark:text-slate-200">
                  Time
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
              {bookingData?.comments?.map((comment, index) => {
                return (
                  <tr>
                    <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {comment.id}
                    </td>
                    <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${comment?.comment || ""} `,
                        }}
                      ></span>
                    </td>
                    <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {comment.commented_by}
                    </td>
                    <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {getReadableDateAndTime(comment.created_at)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* <div className="my-4 rounded-lg border-2 bg-white p-4">
        <div className="flex items-center justify-between border-b-2 pb-4">
          <div>
            <h2 className=" text-xl font-bold">Participant Detail</h2>
            <div className="flex items-center">
              <div className="h-2 w-2 rounded-full bg-[#D83F31]"></div>
              <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#D83F31]">
                Cancelled Participants
              </p>
            </div>
          </div>

          {bookingData?.cancellation?.cancellation_type !== "full" && (
            <div>
              {(auth?.permissions?.includes("Booking.cancellation") ||
                auth?.roles?.includes("Superadmin")) && (
                <button
                  onClick={() => setParticipantModalIsOpen(true)}
                  className="mr-4 rounded-lg bg-green-500 px-16 py-2 text-white"
                >
                  Update Participants
                </button>
              )}
              <button
                onClick={UpdateParticipantsNotification}
                className="rounded-lg bg-blue-500 px-16 py-2 text-white"
              >
                Send Participants Detail Update Notification
              </button>
            </div>
          )}
        </div>
        <div className="my-4">
          <DataTable
            columns={columns}
            tableData={bookingData?.allparticipants}
            height="auto"
            pagination={false}
            // responsiveLayout="collapse"
            rowFormatter={rowFormatter}
          />
        </div>
      </div> */}

      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <div className="flex flex-col items-start justify-between border-b-2 pb-4 lg:flex-row lg:items-center">
          <div>
            <h2 className="text-xl font-bold">Participant Detail</h2>
          </div>

          {bookingData?.cancellation?.cancellation_type !== "full" && (
            <div className="mt-4 flex flex-col lg:mt-0 lg:flex-row lg:space-x-4">
              {(auth?.permissions?.includes("Booking.cancellation") ||
                auth?.roles?.includes("Superadmin")) && (
                <button
                  onClick={() => setParticipantModalIsOpen(true)}
                  className="mb-2 rounded-lg bg-green-500 px-4 py-2 text-white lg:mb-0 lg:px-16"
                >
                  Update Participants
                </button>
              )}
              <button
                onClick={UpdateParticipantsNotification}
                className="rounded-lg bg-blue-500 px-4 py-2 text-white lg:px-16"
              >
                Send Participants Detail Update Notification
              </button>
            </div>
          )}
        </div>
        <div className="my-4 w-full overflow-auto">
          <DataTable
            columns={columns}
            tableData={bookingData?.allparticipants}
            height="auto"
            pagination={false}
            rowFormatter={rowFormatter}
          />
        </div>

        <div className="mt-2 flex items-center lg:mt-0">
          <div className="h-2 w-2 rounded-full bg-[#D83F31]"></div>
          <p className="ml-2 rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#D83F31]">
            Cancelled Participants
          </p>
        </div>
      </div>

      <Modal
        isOpen={participantModalIsOpen}
        contentLabel="Update Participant Details"
        onAfterClose={getUpdatedData}
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">Update Participants Detail</h2>
          <button
            onClick={() => setParticipantModalIsOpen(false)}
            className="absolute right-4 top-4 cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <UpdateParticipants
            bookingData={bookingData}
            participants={bookingData?.participants}
            travelModes={travelModes}
            riders={riders}
            occupancies={occupancies}
            setModalIsOpen={setParticipantModalIsOpen}
          />
        </div>
      </Modal>

      <Modal
        isOpen={paymentModalIsOpen}
        onAfterClose={() => {
          getUpdatedData();
          setRecieptToUpdate(null);
        }}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 h-fit min-w-[50%] max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">
            Update Payment Reciept #{recieptToUpdate?.id}
          </h2>
          <button
            onClick={() => setPaymentModalIsOpen(false)}
            className="absolute right-4 top-4 cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <UpdatePaymentReciept
            setModalIsOpen={setPaymentModalIsOpen}
            receipt={recieptToUpdate}
          />
        </div>
      </Modal>

      <Modal
        isOpen={particpantIdModalIsOpen}
        onAfterClose={() => setActiveParticipantData(null)}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 h-fit max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">
            {activeParticipantData?.first_name}{" "}
            {activeParticipantData?.last_name}
            's ID Proof
          </h2>
          <button
            onClick={() => setParticpantIdModalIsOpen(false)}
            className="absolute right-4 top-4 cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <div className="rounded-lg border-2">
            <img
              src={`${getUploadsUrl()}/bookings/participants/ids/${
                activeParticipantData?.id_proof
              }`}
              alt=""
              className="max-h-[80vh] rounded-lg"
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={cancellationModal}
        onAfterClose={() => setActiveParticipantData(null)}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 h-fit max-h-[80vh] max-w-[90%] -translate-y-1/2 -translate-x-1/2 overflow-auto rounded-lg bg-white"
      >
        <div className="relative p-8">
          <div className="flex items-center justify-between gap-12">
            <h2 className="text-xl font-bold">Cancel All Participants</h2>
            <button
              onClick={() => setCancellationModal(false)}
              className="cursor-pointer rounded-full border-2 p-2"
            >
              <MdClose />
            </button>
          </div>
          <div className="mt-4">
            <BookingCancellationForm
              bookingData={bookingData}
              participants={bookingData?.participants}
              costing={bookingData?.cost}
              travelModes={travelModes}
              riders={riders}
              occupancies={occupancies}
              setCancellationModal={setCancellationModal}
              onCancelBooking={onCancelBooking}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ViewBooking;
