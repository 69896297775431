import { Link, useParams } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";
import FormIssuedGiftCards from "./FormIssuedGiftCards";
import ComponentHeader from "../../common/ComponentHeader";
import H2 from "../../../../components/headings/H2";
import { useEffect, useState } from "react";
import { getGiftCardbyId } from "../../../services/GiftCardServices";

function EditIssuedGiftCards() {
  const params = useParams()
  const [formData, setFormData] = useState()
  const getInitialData = async () => {
    try {
      const res = await getGiftCardbyId(params.id)
      setFormData(res?.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getInitialData()
  }, [])
  return (
    <div className="h-screen">
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Issued Gift Cards</H2>
        <Link
          to="/gift-cards/issued"
          className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <FormIssuedGiftCards edit={true} formData={formData} id={params.id} />
    </div>
  );
}

export default EditIssuedGiftCards;
