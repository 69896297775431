import React, { useState } from 'react'
import ComponentHeader from '../common/ComponentHeader'
import H2 from '../../../components/headings/H2'
import { Link } from 'react-router-dom'
import { FaListAlt } from 'react-icons/fa'
import CreditNotesAddForm from './CreditNotesAddForm'
import * as Yup from "yup";
import { Form, Formik } from 'formik'
import { addCreditNotes } from '../../services/CreditNotesServices'
import { toast } from 'react-toastify'

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string(),
  email: Yup.string().email().required("Required"),
  phone: Yup.string().required("Required"),
  country_code: Yup.string().required("Required"),
  credit_amount: Yup.number().min(1, "Value must be greater than 0").required("Required"),
  credit_type: Yup.string().required("Required")
});

const AddCreditNotes = () => {
  const [checkingUserData, setCheckingUserData] = useState({
    country_code: '+91',
    phone: ''
  })

  const handleAddingCreditNote = async (values) => {
    try {
      const res = await addCreditNotes(values)
      toast.success(res.data.message)
    } catch (error) {
      toast.error("Error in adding Credit Note")
    }
  }
  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Add Credit Notes</H2>
        <Link
          to="/credit-notes"
          className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <div className="">
        <div className='flex lg:flex-row flex-col gap-3'>
          <div className='flex gap-4 w-full rounded-md  px-3 py-2 flex-col items-start'>
            <Formik
              enableReinitialize
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                country_code: "+91",
                credit_amount: 0,
                credit_type: ""

              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                handleAddingCreditNote(values)
                resetForm()
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <div className="flex flex-wrap gap-4 lg:flex-nowrap">
                    <CreditNotesAddForm setCheckingUserData={setCheckingUserData} checkingUserData={checkingUserData} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCreditNotes