import {
  Element,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function ScrollToSection(section) {
  scroller.scrollTo(section, {
    duration: 1000,
    smooth: "easeInOutQuint",
    containerId: "app",
    offset: -16,
  });
}

export function snakeCaseToCamelCase(key) {
  return key.replace(/_([a-z])/g, (_, m) => m.toUpperCase());
}

export function camelCaseToSnakeCase(key) {
  return key.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);
}

export function formatNumberToTwoDigit(number) {
  if (number < 10) {
    return "0" + number;
  } else {
    return number;
  }
}

export function formatNumberCommaSeparated(number) {
  return new Intl.NumberFormat().format(number);
}

export function formatCurrencyINRCommaSeparated(number) {
  return new Intl.NumberFormat('en-IN', { style: 'currency',
    currency: 'INR' ,maximumSignificantDigits:12}).format(number);
}
// ----------------------------------------------------------------

export function arrayToTree(elements) {
  let prices = [];
  elements.forEach((element) => {
    let selectedPrice = prices.findIndex(
      (price) => price.travel_mode_id === element.travel_mode_id
    );
    if (selectedPrice >= 0) {
      prices[selectedPrice].riders.push(element);
    } else {
      prices.push({
        travel_mode_id: element.travel_mode_id,
        riders: [element],
      });
    }
  });

  return prices;
}

export function getReadableDate(date) {
  return `${new Date(date).getDate()} ${monthShortNames[new Date(date).getMonth()]
    }, ${new Date(date).getFullYear()}`;
}
export function getReadableDateAndTime(date) {
  return `${new Date(date).getDate()} ${monthShortNames[new Date(date).getMonth()]
    }, ${new Date(date).getFullYear()} ${new Date(date).getHours()} : ${new Date(date).getMinutes()}`;
}

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export function isObjectEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}

export function formatINR(value) {
  const options = {
    style: "currency",
    currency: "INR",
  };
  return value.toLocaleString("en-IN", options);
}

export function getColumnMappedArray(data, columns) {
  return data.map((obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (columns[key]) {
        acc[columns[key]] = obj[key];
      }
      return acc;
    }, {});
  });
}

export function areArraysEqual(array1, array2) {
  if (array1?.length !== array2?.length) {
    return false;
  }
  for (let i = 0; i < array1?.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2[i];
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1?.length !== keys2?.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }
  return true;
}

export async function swalReact(props) {
  return await withReactContent(Swal).fire({
    title: <b>{props.title}</b>,
    showCancelButton: props.showCancelButton,
    showConfirmButton: props.showConfirmButton,
    showCloseButton: props.showCloseButton,
    showDenyButton: props.showDenyButton,
    confirmButtonText: props.confirmButtonText,
    cancelButtonText: props.cancelButtonText,
    denyButtonText: props.denyButtonText,
    icon: props.icon,
    text: props.text,
    footer: props.footer,
    imageUrl: props.imageUrl,
    imageHeight: props.imageHeight,
    imageAlt: props.imageAlt,
  })
}

export function getUniqueTimestamp(){
  const timestamp = Date.now();
  const randomComponent = Math.floor(Math.random() * 1000);
  return `${timestamp}-${randomComponent}`;
};
