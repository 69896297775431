import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaFilter, FaPlusSquare } from "react-icons/fa";

import { deletePageContact } from "../../services/PageContactService";
import InputLabel from "../inputs/InputLabel";
import { getAllCategories } from "../../services/CategoryServices";
import { getAllCountriesOption } from "../../services/CountryServices";
import { getAllEmployeesOption } from "../../services/EmployeeServices";
import { getAllStateByCountry } from "../../services/StateServices";
import SelectSearch from "../inputs/SelectSearch";

function PageContactList() {
  const navigate = useNavigate();
  const PageContactTable = useRef();

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const filterRef1 = useRef(null);

  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [selectedPageName, setSelectedPageName] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCountry, setSelctedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const deleteSelectedContact = (id) => {
    if (!window.confirm("Do You really want to delete this gift card?")) return;
    deletePageContact(id)
      .then((res) => {
        toast.success(res.data.message);
        PageContactTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    // if (cell.getRow().getData().page_name !== "default") {
    return `<div class="contact-edit-btn" data-id=${
      cell.getRow().getData().id
    } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg
      x="0px"
      y="0px"
      width="16px"
      height="16px"
      viewBox="0 0 497.182 497.182"
      xml:space="preserve"
    >
      <g>
        <path
          d="M376.956,237.143l58.504-57.637v255.468c0,34.352-27.852,62.207-62.207,62.207H62.209
  c-34.352,0-62.207-27.855-62.207-62.207V123.932c0-34.355,27.855-62.211,62.207-62.211h255.453l-59.848,58.234H58.597v318.359
  h318.359V237.143z M377.469,56.755l62.967,62.985l20.993-20.986l-62.968-62.985L377.469,56.755z M434.211,0l-20.989,20.99
  l62.968,62.968l20.989-20.989L434.211,0z M171.288,262.987l188.91-188.953l62.969,62.984l-188.91,188.938l-91.703,28.677
  L171.288,262.987z M202.596,265.369l10.77,10.953l161.938-159.184l-10.77-10.953L202.596,265.369z"
        />
      </g>
    </svg></div>

    ${
      cell.getRow().getData().page_name !== "default"
        ? `<div class="contact-delete-btn" data-id=${
            cell.getRow().getData().id
          } style="cursor: pointer;">
    <svg width="16px" height="16px" x="0px" y="0px" fill="red"
     viewBox="0 0 172.541 172.541" style="enable-background:new 0 0 172.541 172.541;" xml:space="preserve">
<g>
    <path d="M166.797,25.078h-13.672h-29.971V0H49.388v25.078H19.417H5.744v15h14.806l10,132.463h111.443l10-132.463h14.805V25.078z
         M64.388,15h43.766v10.078H64.388V15z M128.083,157.541H44.46L35.592,40.078h13.796h73.766h13.796L128.083,157.541z"/>
    <rect x="80.271" y="65.693" width="12" height="66.232"/>
    <rect x="57.271" y="65.693" width="12" height="66.232"/>
    <rect x="103.271" y="65.693" width="12" height="66.232"/>
</g>
</svg></div>
  </div>`
        : ``
    }`;
    // }
  };

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(
        res.data.data.data.map((onecat) => ({
          label: onecat.name,
          value: onecat.id,
        }))
      );
    });

    getAllCountriesOption().then((res) => {
      setCountries(
        res.data.data.map((onecont) => ({
          label: onecont.name,
          value: onecont.id,
        }))
      );
    });

    getAllEmployeesOption().then((res) => {
      setEmployees(
        res.data.data.data.map((oneemp) => ({
          label: oneemp.full_name,
          value: oneemp.id,
        }))
      );
    });

    getAllStateByCountry(101).then((res) => {
      setStates(
        res.data.data.map((onest) => ({
          label: onest.name,
          value: onest.id,
        }))
      );
    });
  }, []);


  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Page Name",
      field: "page_name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Category",
      field: "category",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Country",
      field: "country",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },

    {
      title: "State",
      field: "state",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Phone",
      field: "phone",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Whatsapp",
      field: "watsapp",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".contact-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/page-contacts/edit/${cell}`);
    });

    $(document).on("click", ".contact-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedContact(dataId);
    });

    return () => {
      $(document).off("click", ".contact-edit-btn");
      $(document).off("click", ".contact-delete-btn");
    };
  }, []);

  useEffect(() => {
    PageContactTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  return (
    <div className="flex h-full flex-col dark:bg-slate-900 dark:text-white">
      <ComponentHeader className="border-b">
        <h2 className="text-xl font-bold">Page Contacts</h2>
        <Link
          to="/page-contacts/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-800 lg:px-12 lg:py-2"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        <div
          ref={filterRef1}
          // onClick={() => setFilterOpen(!filterOpen)}
          className=" mr-2 inline-block pt-4"
        >
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={` flex items-center whitespace-nowrap rounded-md border bg-white p-4 py-2 text-lg font-semibold tracking-wide dark:bg-slate-800 ${
              filterOpen
                ? "-mb-[1px] rounded-b-none border-b-0 px-8 pb-4 pt-2"
                : ""
            }`}
          >
            <FaFilter />
            Filters
          </button>
        </div>

        <div className="rounded-md my-2">
          <div
            className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-700 dark:text-white ${
              filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
            }`}
          >
            <div className="min-w-full lg:min-w-[250px]">
              <InputLabel label="Page Name" />
              <SelectSearch
                options={[
                  { value: "home-page", label: "Home Page" },
                  { value: "category", label: "Category" },
                  { value: "career-page", label: "Career Page" },
                  { value: "internships", label: "Internships" },
                  { value: "contact-page", label: "Contact Page" },
                  { value: "about-page", label: "About Page" },
                  { value: "corporate", label: "Corporate" },
                  { value: "all-girls-trips", label: "All Girls Trips" },
                  {
                    value: "sale-of-the-season",
                    label: "Sale of the Season",
                  },
                  { value: "newsletter", label: "Newsletter" },
                  { value: "corporate-program", label: "Corporate Program" },

                  {
                    value: "long-weekend-trips",
                    label: "Long Weekend Trips",
                  },
                  { value: "blog", label: "Blog" },
                ]}
                value={selectedPageName}
                onChange={(value) => {
                  setSelectedPageName(value);
                  updateFilter({
                    field: "page_name",
                    type: "like",
                    value: value,
                  });
                }}
              />
            </div>
            {selectedPageName && (
              <div className="min-w-full lg:min-w-[250px]">
                <InputLabel label="Category" />
                <SelectSearch
                  options={categories}
                  value={selectedCategory}
                  onChange={(value) => {
                    setSelectedCategory(value);
                    updateFilter({
                      field: "category_id",
                      type: "like",
                      value: value,
                    });
                  }}
                />
              </div>
            )}

            {selectedPageName && (
              <div className="min-w-full lg:min-w-[250px]">
                <InputLabel label="Country" />
                <SelectSearch
                  options={countries}
                  value={selectedCountry}
                  onChange={(value) => {
                    setSelctedCountry(value);
                    updateFilter({
                      field: "country_id",
                      type: "like",
                      value: value,
                    });
                  }}
                />
              </div>
            )}

            {selectedCountry === 101 && selectedPageName && (
              <div className="min-w-full lg:min-w-[250px]">
                <InputLabel label="Page Name" />
                <SelectSearch
                  options={states}
                  value={selectedState}
                  onChange={(value) => {
                    setSelectedState(value);
                    updateFilter({
                      field: "state_id",
                      type: "like",
                      value: value,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <DataTable
          ref={PageContactTable}
          ajaxUrl={`${getAdminUrl()}/page-contact`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default PageContactList;
