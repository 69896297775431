import React from "react";
import ImageInput from "../inputs/ImageInput";
import { Form, Formik } from "formik";
import { getUploadsUrl } from "../../../ServiceConfig";
import { updateListingSeoData } from "../../services/ListingSeoServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUniqueTimestamp } from "../../../helpers/Helper";

const FormStep2 = ({ edit, onSuccess, formData, listingSEOId, listingSEOTitle, listingSEOSlug }) => {
    console.log(formData, edit)
    const navigate = useNavigate();
    const desktopLandscapeImageConfig = {
        label: "Crop Image For Desktop Landscape",
        x: 500,
        y: 310,
        sample: `${getUploadsUrl()}/listing-seo/samples/500X310.jpg`,
        path: "listing-seo/original/cropped",
    };

    const desktopSquareImageConfig = {
        label: "Crop Image For Desktop Square",
        x: 500,
        y: 500,
        sample: `${getUploadsUrl()}/listing-seo/samples/500X500.jpg`,
        path: "listing-seo/original/cropped",
    };

    const mobileImageConfig = {
        label: "Crop Image For Mobile",
        x: 420,
        y: 315,
        sample: `${getUploadsUrl()}/listing-seo/samples/420X315.jpg`,
        path: "listing-seo/original/cropped",
    };

    const addListingSeoImages = (values) => {
        updateListingSeoData(listingSEOId, values)
            .then((res) => {
                toast.success(res.data.message);
                navigate(`/listing-seo`);
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.response.data.message);
            });
    };

    const renderImageInput = (title, config, bannerType, setFieldValue, values) => (
        <div className="max-w-[700px]">
            <h2 className="text-lg font-semibold mb-1">{title}</h2>
            <ImageInput
                allowedFiles={1}
                path="listing-seo/original"
                cropRatios={[config]}
                listingSEO={true}
                images={
                    values.images.find((img) => img.banner_type === bannerType)
                        ? [values.images.find((img) => img.banner_type === bannerType)]
                        : []
                }
                thumbSize="!w-[328px] !h-[120px]"
                onImageCrop={(pic) => {
                    const image = pic.replace("listing-seo/original/cropped/", "");
                    const updatedImage = {
                        listing_page_seo_id: listingSEOId,
                        image,
                        caption: values.images.find((img) => img.banner_type === bannerType)
                            ? values.images.find((img) => img.banner_type === bannerType)?.caption
                            : "",
                        banner_type: bannerType,
                    };
                    const updatedImages = [
                        ...values.images.filter((img) => img.banner_type !== bannerType),
                        updatedImage,
                    ];
                    setFieldValue("images", updatedImages);
                }}
            />
            <input
                disabled={
                    !values.images.some((img) => img.banner_type === bannerType && img.image)
                }
                type="text"
                className="w-[328px] mt-2 p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Write caption here..."
                value={
                    values.images.find((img) => img.banner_type === bannerType)?.caption || ""
                }
                onChange={(e) => {
                    const updatedImages = values.images.map((img) =>
                        img.banner_type === bannerType
                            ? { ...img, caption: e.target.value }
                            : img
                    );
                    setFieldValue("images", updatedImages);
                }}
            />
        </div>
    );

    return (
        <div className="flex w-full flex-col gap-10 lg:overflow-hidden">
            <Formik
                enableReinitialize
                initialValues={{
                    images: formData?.images ? formData?.images : [],
                    title: listingSEOTitle,
                    slug: listingSEOSlug,
                }}
                onSubmit={(values, { isSubmitting }) => {
                    // console.log(values)
                    addListingSeoImages(values, isSubmitting);
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form className="flex flex-col lg:gap-10 gap-[1rem] justify-center w-full">
                        {/* Desktop Section */}
                        <div className="flex lg:flex-row flex-col w-full lg:h-screen gap-10">
                            <div className="flex lg:w-2/3 w-full flex-col lg:gap-[5rem] gap-[2rem] lg:overflow-y-auto lg:h-screen no-scrollbar lg:mb-10">
                                <div className="w-full flex flex-col gap-1">
                                    <h2 className="text-xl font-bold">Landscape</h2>
                                    {renderImageInput(
                                        "Desktop Landscape 1 Size - (500 X 310)",
                                        desktopLandscapeImageConfig,
                                        "desktop_landscape-1",
                                        setFieldValue,
                                        values
                                    )}
                                    {renderImageInput(
                                        "Desktop Landscape 2 Size - (500 X 310)",
                                        desktopLandscapeImageConfig,
                                        "desktop_landscape-2",
                                        setFieldValue,
                                        values
                                    )}
                                </div>
                                <div className="w-full flex flex-col gap-1 pl-4">
                                    <h2 className="text-xl font-bold mb-2">Square</h2>
                                    {renderImageInput(
                                        "Desktop Square Size 1 - (500 X 500)",
                                        desktopSquareImageConfig,
                                        "desktop_square-1",
                                        setFieldValue,
                                        values
                                    )}
                                    {renderImageInput(
                                        "Desktop Square Size 2 - (500 X 500)",
                                        desktopSquareImageConfig,
                                        "desktop_square-2",
                                        setFieldValue,
                                        values
                                    )}
                                </div>
                                <div className="w-full flex flex-col gap-1 pl-4">
                                    <h2 className="text-xl font-bold">Mobile</h2>
                                    {renderImageInput(
                                        "Mobile Image Size - (420 X 315)",
                                        mobileImageConfig,
                                        "mobile",
                                        setFieldValue,
                                        values
                                    )}
                                </div>
                                <div className="text-center  lg:mb-[10rem]">
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className="w-[1px] bg-black mx-4 lg:block hidden"></div>
                            <div className="lg:w-1/3 w-full lg:overflow-hidden">
                                <h2 className="text-xl text-center font-bold mb-4">Preview</h2>
                                <div className="flex flex-col gap-12">
                                    <div className="flex flex-col">
                                        <h3 className="text-xl font-bold mb-4">Desktop</h3>
                                        <div className="grid grid-cols-2 grid-rows-5 lg:gap-[1.875rem] gap-[0.625rem]">
                                            <div className="col-span-1 row-span-3 row-start-1">
                                                <img
                                                    src={values.images.find((img) => img.banner_type === "desktop_square-1")?.image ? `${getUploadsUrl()}/listing-seo/original/cropped/${values.images.find((img) => img.banner_type === "desktop_square-1")?.image + "?v=" + getUniqueTimestamp()
                                                        }` : `${getUploadsUrl()}/listing-seo/samples/500X500.jpg`}
                                                    alt="square 1"
                                                    width={500}
                                                    height={500}
                                                    className="h-full w-full rounded-[1.25rem] object-cover border-2"
                                                />
                                            </div>
                                            <div className="col-span-1 col-start-2 row-span-2 row-start-1">
                                                <img
                                                    src={values.images.find((img) => img.banner_type === "desktop_landscape-1")
                                                        ?.image ? `${getUploadsUrl()}/listing-seo/original/cropped/${values.images.find((img) => img.banner_type === "desktop_landscape-1")
                                                            ?.image + "?v=" + getUniqueTimestamp()
                                                        }` : `${getUploadsUrl()}/listing-seo/samples/500X310.jpg`}
                                                    alt="landscape 1"
                                                    width={500}
                                                    height={310}
                                                    className="h-full w-full rounded-[1.25rem] object-cover"
                                                />
                                            </div>
                                            <div className="col-span-1 row-span-2 row-start-4">
                                                <img
                                                    src={values.images.find((img) => img.banner_type === "desktop_landscape-2")?.image ? `${getUploadsUrl()}/listing-seo/original/cropped/${values.images.find((img) => img.banner_type === "desktop_landscape-2")?.image + "?v=" + getUniqueTimestamp()
                                                        }` : `${getUploadsUrl()}/listing-seo/samples/500X310.jpg`}
                                                    alt="landscape 2"
                                                    width={500}
                                                    height={350}
                                                    className="h-full w-full rounded-[1.25rem] object-cover"
                                                />
                                            </div>
                                            <div className="col-span-1 col-start-2 row-span-3 row-start-3">
                                                <img
                                                    src={values.images.find((img) => img.banner_type === "desktop_square-2")?.image ? `${getUploadsUrl()}/listing-seo/original/cropped/${values.images.find((img) => img.banner_type === "desktop_square-2")?.image + "?v=" + getUniqueTimestamp()
                                                        }` : `${getUploadsUrl()}/listing-seo/samples/500X310.jpg`}
                                                    alt="square 2"
                                                    width={390}
                                                    height={460}
                                                    className="h-full w-full rounded-[1.25rem] object-cover"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <h3 className="text-xl font-bold lg:mb-4">Mobile</h3>
                                        <div className="grid grid-cols-2 grid-rows-5 gap-[1.875rem]">
                                            <div className="lg:col-span-1 lg:row-span-1 col-span-2 row-span-4">
                                                <img
                                                    src={values.images.find((img) => img.banner_type === "mobile")?.image ? `${getUploadsUrl()}/listing-seo/original/cropped/${values.images.find((img) => img.banner_type === "mobile")?.image + "?v=" + getUniqueTimestamp()}` : `${getUploadsUrl()}/listing-seo/samples/420X315.jpg`}
                                                    alt="Mobile"
                                                    width={360}
                                                    height={640}
                                                    className="h-full w-full rounded-[1.25rem] object-cover"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default FormStep2;
