import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";
import { getAllStateByCountry } from "../../services/StateServices";
import { getAllCountriesOption } from "../../services/CountryServices";
import {
  updateLocation,
  uploadLocationImage,
} from "../../services/LocationServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { createLocation } from "../../services/LocationServices";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";
import EditorInput from "../inputs/EditorInput";
import { createJobOpening, updateJobOpening } from "../../services/JobServices";
import { getAllDepartments } from "../../services/DepartmentServices";
import SelectSearch from "../inputs/SelectSearch";

const JobFormSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  salary: Yup.string().required("Required"),
  experience: Yup.string().required("Required"),
  skills: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  currently_hiring: Yup.string().required("Required"),
});

function JobForm({ formData, edit, onSuccess }) {
  // ? Add new Location

  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    getAllDepartments().then((res) => {
      let dpts = [];

      res.data.data.forEach((data) => {
        dpts.push({ label: data.name, value: `${data.id}` });
      });

      setDepartments(dpts);
    });
  }, []);

  const addJobOpening = (values) => {
    let data = Object.assign({}, values);
    createJobOpening(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update jobs
  const editJobOpening = (values) => {
    let data = Object.assign({}, values);
    updateJobOpening(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: formData?.title,
          role: formData?.role,
          experience: formData?.experience,
          department_id: `${formData?.department_id}`,
          salary: formData?.salary,
          skills: formData?.skills,
          description: formData?.description,
          currently_hiring: `${formData?.currently_hiring}`,
          order: formData?.order,
          meta_title: formData?.meta_title,
          meta_description: formData?.meta_description,
          meta_keywords: formData?.meta_keywords,
        }}
        validationSchema={JobFormSchema}
        onSubmit={(values) => {
          console.log(values, "vvvvvvvvvvv===========");
          edit ? editJobOpening(values) : addJobOpening(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-wrap gap-4">
              {/* //? location Name */}
              <div className="max-w-[50%] grow basis-[45%]">
                <InputLabel label="Title" labelFor="" />
                <Field className="field" name="title" />
                <ErrorMessage name="title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? Location Tag Line */}
              <div className="max-w-[50%] grow basis-[45%]">
                <InputLabel label="Role" labelFor="" />
                <Field className="field" name="role" />
                <ErrorMessage name="role">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="my-4 flex flex-wrap gap-4">
              {/* //? Select Country */}
              <div className="max-w-[50%] grow basis-[45%]">
                <InputLabel label="Experience" labelFor="" />
                <Field name="experience" className="field" />

                <ErrorMessage name="experience">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="max-w-[50%] grow basis-[45%]">
                <InputLabel label="salary" labelFor="" />
                <Field
                  name="salary"
                  className="field disabled:cursor-not-allowed"
                />
                <ErrorMessage name="salary">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
            <div className="my-4 flex flex-wrap gap-4">
              <div className="max-w-[50%] grow basis-[45%]">
                <InputLabel label="Select Department" required={true} />
                <SelectSearch
                  value={values.department_id}
                  options={departments}
                  onChange={(value) => setFieldValue("department_id", value)}
                />

                <ErrorMessage name="experience">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div>
              <InputLabel label="Skills" labelFor="" />
              <EditorInput
                fieldKey="skills"
                data={values.skills}
                onChange={(data) => setFieldValue("skills", data)}
              />
            </div>
            <div className="mt-4">
              <InputLabel label="Description" labelFor="" />
              <EditorInput
                fieldKey="description"
                data={values.description}
                onChange={(data) => setFieldValue("description", data)}
              />
            </div>

            {/* //? Location Status */}
            <div className="my-4">
              <div role="group" aria-labelledby="my-radio-group">
                <InputLabel label="Currently Hiring" />
                <div className="flex items-center gap-12">
                  <label className="flex items-baseline gap-1">
                    <Field type="radio" name="currently_hiring" value="1" />
                    Yes
                  </label>
                  <label className="flex items-baseline gap-1">
                    <Field type="radio" name="currently_hiring" value="0" />
                    No
                  </label>
                </div>
              </div>
            </div>

            {/* //? Meta Fields */}
            <div className="my-4 rounded-lg border p-4">
              <h3 className="mb-2 text-lg font-bold">Meta Info</h3>

              {/* //? Location Meta Title */}
              <div>
                <InputLabel
                  label="Meta Title"
                  labelFor="meta_title"
                  instruction="(Max 70 Characters)"
                />
                <Field className="field" name="meta_title" />
                <ErrorMessage name="meta_title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? location Meta Description */}
              <div className="my-4">
                <InputLabel
                  label="Meta Description"
                  labelFor="meta_description"
                  instruction="(Max 160 Characters)"
                />
                <Field
                  name="meta_description"
                  as="textarea"
                  className="field"
                ></Field>
                <ErrorMessage name="meta_description">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? Location Meta Keywords */}
              <div>
                <InputLabel label="Meta Keywords" />
                <KeywordsInput
                  value={
                    values?.meta_keywords
                      ? values?.meta_keywords?.split(",")?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      : []
                  }
                  onChange={(value) =>
                    setFieldValue(
                      "meta_keywords",
                      value.map((v) => v.value).toString()
                    )
                  }
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default JobForm;
