import React, { useEffect, useState } from 'react'
import { deleteLeadCategory, getAllLeadCategory } from '../services/LeadService'
import SelectSearch from '../components/inputs/SelectSearch'
import { FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'

const CategoryDeleteModal = ({ selectedLeadCategory, leadCategoryTable, setOpenCategoryModal }) => {
  const [allLeadCategories, setAllLeadCategories] = useState([])
  const [selected, setSelected] = useState()
  const allLeadCategoriesGetter = async () => {
    const res = await getAllLeadCategory()
    const arrayOfCats = res.data.data.data.map((oneCat) => ({
      label: oneCat?.category,
      value: oneCat.id
    }))
    setAllLeadCategories(arrayOfCats.filter((oneCat) => oneCat.value !== selectedLeadCategory))
  }
  useEffect(() => {
    allLeadCategoriesGetter()
  }, [])

  const onDelete = () => {
    deleteLeadCategory(selectedLeadCategory, { new: selected })
      .then((res) => {
        leadCategoryTable.current.reloadTable();
        setOpenCategoryModal(false)
        toast.success("Deleted Successfully")
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }
  return (
    <div className='flex lg:flex-row flex-col lg:gap-4 gap-1 items-center'>
      <div className='min-w-[300px]'>
        <SelectSearch
          placeholder=""
          className='dark:text-black'
          value={selected
          }
          options={allLeadCategories}
          onChange={(value) => {
            setSelected(value)
          }
          }
        />
      </div>
      <button
        disabled={!selected}
        onClick={onDelete}
        className="flex items-center lg:my-4 my-1 justify-center disabled:bg-opacity-50 p-2 bg-red-500 text-white rounded hover:bg-red-600"
      >
        <FaTrash className="w-4 h-4" /> Delete
      </button>
    </div>
  )
}

export default CategoryDeleteModal