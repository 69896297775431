import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import DataTable from "../common/DataTable";
import { useEffect, useState } from "react";
import { getAdminUrl } from "../../../ServiceConfig";
import { useRef } from "react";
import $ from "jquery";
import GiftCardCategoryForm from "../gift-card/gift-card-forms/GiftCardCategoryForm";
import { deleteGiftCardCategory, getGiftCardCategories, getGiftcardCategoryById } from "../../services/GiftCardServices";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { FaPlusSquare } from "react-icons/fa";
import { toast } from "react-toastify";

function GiftCardCategories() {
  const giftCardCategoryTable = useRef();
  const [giftCardCategories, setGiftCardCategories] = useState([]);
  const [categoryModal, setCategoryModal] = useState(false);
  const [editId, setEditId] = useState()
  const [formData, setFormData] = useState()

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
      <div class="flex items-center gap-3">
   <div class="gc-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
        <div class="gc-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      </div>
  `;
  };

  const deleteGiftCardCategoryFunc = async (id) => {
    if (!window.confirm("Are You sure want to delete this Gift Card Category?")) return;
    try {
      const res = await deleteGiftCardCategory(id)
      giftCardCategoryTable?.current?.reloadTable()
      toast.success(res.data.data)
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message)
    }
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: "required",
      resizable: true,
    },

    {
      title: "Description",
      field: "description",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Status",
      field: "status",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      validator: "required",
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const getCategories = () => {
    getGiftCardCategories().then((res) => {
      setGiftCardCategories(res.data.data.data);
    });
  };

  useEffect(() => {
    $(document).on("click", ".gc-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      setEditId(cell)
      setCategoryModal(true)
    });

    $(document).on("click", ".gc-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      deleteGiftCardCategoryFunc(cell)
    });
  }, []);

  const getInitialData = async () => {
    try {
      const res = await getGiftcardCategoryById(editId)
      if (res?.data?.data) {
        setFormData({
          name: res.data.data.name,
          description: res.data.data.description,
          status: res.data.data.status
        })
      }
    } catch (error) {
      console.log(error, "error in getting data of editing gift card category")
    }
  }

  useEffect(() => {
    if (editId) {
      getInitialData()
    }
  }, [editId])

  return (
    <div className="wrapper">
      {/* <div className="h-full max-h-[calc(100%-1rem)] overflow-auto"> */}
      <ComponentHeader className="border-b">
        <H2>Gift Card Categories</H2>
        <div className="btn-primary cursor-pointer flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2" onClick={() => {
          setEditId()
          setFormData()
          setCategoryModal(true)
        }}>
          <FaPlusSquare /> Add New
        </div>
      </ComponentHeader>


      <div className="">
        <DataTable
          ref={giftCardCategoryTable}
          ajaxUrl={`${getAdminUrl()}/gift-card/category`}
          layout="fitColumns"
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
      {/* </div> */}

      <Modal
        isOpen={categoryModal}
        contentLabel="Add new Gift Card Category"
        className="absolute top-1/2 left-1/2 h-fit  lg:min-w-[40%] min-w-[85%] max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          {editId ? <h2 className="mb-4 text-xl font-bold">Update Gift Card Category</h2> : <h2 className="mb-4 text-xl font-bold">Add New Gift Card Category</h2>}
          <button
            onClick={() => setCategoryModal(false)}
            className="absolute right-4 top-4 rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <GiftCardCategoryForm
            getCategories={getCategories}
            closeModal={() => setCategoryModal(false)}
            editId={editId}
            formData={formData}
            giftCardCategoryTable={giftCardCategoryTable}
            getInitialData={getInitialData}
          />
        </div>
      </Modal>
    </div>
  );
}

export default GiftCardCategories;
