import { Link } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";
import FormIssuedGiftCards from "./FormIssuedGiftCards";
import ComponentHeader from "../../common/ComponentHeader";
import H2 from "../../../../components/headings/H2";
import { useState } from "react";
import BulkIssueGiftCard from "./BulkIssueGiftCard";


function AddIssuedGiftCard() {
  const [activeTab, setActiveTab] = useState("single");
  return (
    <div className="h-screen">
      <ComponentHeader className="mb-4 border-b">
        <H2>Add Issued Gift Cards</H2>
        <Link
          to="/gift-cards/issued"
          className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>


      <div className="w-full">

        {/* Tabs */}
        <div className="flex border-b border-gray-300">
          <button
            className={`px-4 py-2 text-lg ${activeTab === "single"
              ? "text-[#0183C0] font-bold border-b-4 border-[#0183C0]"
              : "text-gray-600"
              }`}
            onClick={() => setActiveTab("single")}
          >
            Single Gift Card
          </button>
          <button
            className={`px-4 py-2 text-lg ${activeTab === "bulk"
              ? "text-[#0183C0] font-bold border-b-4 border-[#0183C0]"
              : "text-gray-600"
              }`}
            onClick={() => setActiveTab("bulk")}
          >
            Bulk Import
          </button>
        </div>

        {/* component */}
        <div
          className={`transition-opacity duration-300 ease-in-out ${activeTab === "single" ? "opacity-100" : "opacity-0"
            }`}
        >
          {activeTab === "single" && <FormIssuedGiftCards />}
        </div>
        <div
          className={`transition-opacity duration-300 ease-in-out ${activeTab === "bulk" ? "opacity-100" : "opacity-0"
            }`}
        >
          {activeTab === "bulk" && <BulkIssueGiftCard />}
        </div>
      </div>
      {/* <FormIssuedGiftCards /> */}
    </div>
  );
}

export default AddIssuedGiftCard;
