import React, { useState } from 'react'
import InputLabel from '../inputs/InputLabel';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import PhoneNumberInput from '../../../components/inputFields/PhoneInput';
import { useEffect } from 'react';
import { checkUserExistbyPhone } from '../../services/CreditNotesServices';
import { toast } from 'react-toastify';
import { swalReact } from '../../../helpers/Helper';

const TransferFormikForm = ({ setCheckingUserData, checkingUserData, setUserData }) => {
    const { values, setFieldValue } = useFormikContext();
    const [searchInProgress, setSearchInProgress] = useState(false);
    
    const handleCheckUserExist = async () => {
        if (searchInProgress) return;
        setSearchInProgress(true);
        if (checkingUserData?.country_code && checkingUserData?.phone) {
            try {
                const res = await checkUserExistbyPhone(checkingUserData)
                toast.success(res.data.message)
                if (res.data.data.first_name) {
                    setFieldValue('fName', res?.data?.data?.first_name)
                    setFieldValue('lName', res?.data?.data?.last_name)
                    setFieldValue('email', res?.data?.data?.email)
                } else {
                    swalReact({ title: "User Not Found!", text: "If You want to Create a New User Please Fill Personal Details and Submit..", showConfirmButton: true, confirmButtonText: true })
                    setFieldValue('fName', "")
                    setFieldValue('lName', "")
                    setFieldValue('email', "")
                }
            } catch (error) {
                console.log(error)
            }
        }
        else {
            toast.error("type a valid phone number")
        }
        setSearchInProgress(false);
    }

    useEffect(() => {
        setUserData({
            fName: values?.fName,
            lName: values?.lName,
            email: values?.email
        })
    }, [values?.fName, values?.lName, values?.email])

    return (
        <div className="my-4 grow">
            <div className="flex flex-wrap gap-4">
                {/* Phone Number */}
                <div className="grow basis-[45%] lg:max-w-[50%]">
                    <PhoneNumberInput
                        label="Phone Number"
                        onBlur={handleCheckUserExist}
                        phone={`+${values.code} ${values.phone}`}
                        onChange={(value, data) => {
                            if (value.slice(data.dialCode.length) !== checkingUserData?.phone) {
                                setFieldValue(`code`, data.dialCode);
                                setFieldValue(
                                    `phone`,
                                    value.slice(data.dialCode.length)
                                );
                                setCheckingUserData(
                                    {
                                        country_code: data.dialCode,
                                        phone: value.slice(data.dialCode.length)
                                    }
                                )
                            }
                        }}
                    />
                    <ErrorMessage name="phone">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>
                <div className="flex justify-center items-end">
                    <button
                        onClick={handleCheckUserExist}
                        className="bg-[#0183c0] text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-200"
                    >
                        Search
                    </button>
                </div>

                {/* //? First Name */}
                <div className="grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="First Name" labelFor="fName" required={true} />
                    <Field
                        className="field"
                        id="fName"
                        name="fName"
                    />
                    <ErrorMessage name="fName">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>

                {/* //? Last Name */}
                <div className="grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Last Name" labelFor="lName" />
                    <Field
                        className="field"
                        id="lName"
                        name="lName"
                    />
                    <ErrorMessage name="lName">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>

                {/* //? Email */}
                <div className="grow basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Email" labelFor="email" required={true} />
                    <Field
                        className="field"
                        id="email"
                        name="email"
                    />
                    <ErrorMessage name="email">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>
            </div>
        </div>
    )
}

export default TransferFormikForm