import { Formik, Form, Field, ErrorMessage } from "formik";
import InputLabel from "../../inputs/InputLabel";
import { addGiftCardCategory, updateGiftcardCategory } from "../../../services/GiftCardServices";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ToggleInput from "../../inputs/ToggleInput";

const giftCardCategoryFormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string(),
});

function GiftCardCategoryForm({ getCategories, closeModal, editId, formData, giftCardCategoryTable, getInitialData }) {
  const addCategory = async (values) => {
    try {
      const res = await addGiftCardCategory(values)
      toast.success(res.data.message);
      getCategories();
      closeModal();
      giftCardCategoryTable?.current?.reloadTable()
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const editCategory = async (values) => {
    try {
      const res = await updateGiftcardCategory(editId, values)
      getInitialData(editId)
      toast.success(res.data.message);
      closeModal()
      giftCardCategoryTable?.current?.reloadTable()
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className=" mt-4">
      <Formik
        enableReinitialize
        initialValues={{
          name: formData?.name ? formData?.name : "",
          description: formData?.description ? formData?.description : "",
          status: formData?.status === 1 ? 1 : 0
        }}
        validationSchema={giftCardCategoryFormSchema}
        onSubmit={(values) => {
          editId ? editCategory(values) : addCategory(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="mt-4">
              <div className="rounded-lg border p-4">
                <div className="flex gap-6">
                  <div className="w-full">
                    <InputLabel label="Name" />
                    <Field className="field" name="name" required={true} />
                    <ErrorMessage name={`name`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div>
                    <InputLabel label="Status" />
                    <ToggleInput
                      checked={values.status}
                      onToggle={(e) => setFieldValue("status", e.target.checked ? 1 : 0)}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <InputLabel label="Description" />
                  <Field className="field" as="textarea" name="description" />
                </div>
              </div>
              <div className="flex justify-center mt-4">
                {editId ? (
                  <button type="submit" className="btn-primary text-center">
                    Update Category
                  </button>
                ) : (
                  <button type="submit" className="btn-primary text-center">
                    Add Category
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GiftCardCategoryForm;
