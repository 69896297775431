import { ErrorMessage, Field, useFormikContext } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import {
  formatNumberToTwoDigit,
  getReadableDate,
} from "../../../helpers/Helper";
import { getAllCountriesOption } from "../../services/CountryServices";
import {
  getAllItinerariesByPackageId,
  getPackagesByCountry,
  getPackagesByState,
} from "../../services/PackageService";

import { getAllStateByCountry } from "../../services/StateServices";
import InputLabel from "../../components/inputs/InputLabel";
import { getBatchesByItinerary } from "../../services/EventDatesServices";
import SelectSearch from "../../components/inputs/SelectSearch";
import DateInput from "../../components/inputs/DateInput";
import Collapsible from "../../../hooks/Collapsible";

function PackageDetailsForm({
  edit,
  setPackageName,
  isCustomized,
  selectedIndex,
  setSelectedIndex,
}) {
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [packages, setPackages] = useState([]);
  const [itineraries, setItineraries] = useState([]);

  const [batches, setBatches] = useState([]);

  useEffect(() => {
    if (!values.country_id) return;
    setStates([]);
    setPackages([]);

    if (values.country_id === 101) {
      // if country is India then get states by country
      getAllStateByCountry(values.country_id).then((res) => {
        setStates(res.data.data);
      });
    } else {
      // get packages by country
      getPackagesByCountry(values.country_id).then((res) => {
        setPackages(res.data.data);
      });
    }
  }, [values.country_id]);

  useEffect(() => {
    if (!values.state_id) return;
    setPackages([]);
    getPackagesByState(values.state_id).then((res) => {
      setPackages(res.data.data);
    });
  }, [values.state_id]);

  useEffect(() => {
    if (!values.package_id || packages.length === 0 || isCustomized === 1)
      return;

    setItineraries([]);

    let pkg = packages.filter((pkg) => pkg.id === parseInt(values.package_id));

    setPackageName(pkg[0]?.title);

    getAllItinerariesByPackageId(values.package_id).then((res) => {
      let itineraries = [];
      res.data.data.forEach((data) => {
        itineraries.push({
          no_of_days: data.no_of_days,
          starting_location: data.starting_location_name,
          ending_location: data.ending_location_name,
          value: `${data.id}`,
          label: `${data.starting_location_name} - ${data.ending_location_name}`,
        });
      });
      if (itineraries.length > 0)
        setFieldValue("itinerary_id", itineraries[0].value);
      setItineraries(itineraries);
    });
  }, [values.package_id, packages]);

  useEffect(() => {
    if (values.itinerary_id === "") return;
    if (!edit)
      setFieldValue("addons", [
        {
          title: "",
          description: "",
          unit_type: "",
          unit_price: 0,
          unit_gst: 0,
          unit: 0,
          amount: 0,
        },
      ]);

    let selectedItinerary = itineraries.filter(
      (itinerary) => itinerary.value === values.itinerary_id
    );

    setFieldValue(
      "no_of_days",
      selectedItinerary[0]?.no_of_days
        ? selectedItinerary[0]?.no_of_days
        : values.no_of_days
    );

    getBatchesByItinerary(values.itinerary_id).then((res) => {
      let batches = [];
      res.data.data.data.forEach((data) => {
        batches.push({
          value: data.startdate,
          label: `${getReadableDate(data.startdate)} - ${getReadableDate(
            data.lastdate
          )}`,
        });
      });
      batches.push({
        value: `Others`,
        label: `Others`,
      });
      if (!edit) {
        setFieldValue("event_date", batches[0].value);
      }
      setBatches(batches);
    });
  }, [values.itinerary_id]);

  useEffect(() => {
    getAllCountriesOption()
      .then((res) => {
        let countries = [];
        res.data.data.forEach((data) => {
          countries.push({ value: data.id, label: data.name });
        });
        setCountries(countries);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Collapsible
        heading="Package Details"
        selectedIndex={selectedIndex}
        index={1}
        setSelectedIndex={setSelectedIndex}
        hasErrors={
          (errors.country_id && touched.country_id) ||
          (errors.state_id && touched.state_id) ||
          (errors.event_date && touched.event_date) ||
          (errors.package_id && touched.package_id) ||
          (errors.package_name && touched.package_name)
        }
      >
        <div className="rounded-lg border border-slate-200  bg-white  p-4 dark:border-slate-700 dark:bg-slate-800">
          <div className="col-span-12 col-start-1 row-span-1 row-start-1 my-4 grid grid-cols-12 items-end gap-4">
            <div className="col-span-12 md:col-span-3">
              <InputLabel label="Select Country" required={true} />
              <SelectSearch
                options={countries}
                value={parseInt(values.country_id)}
                onChange={(value) => setFieldValue("country_id", value)}
              />
              <ErrorMessage name="country_id">
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            {values.country_id === 101 && (
              <div className="col-span-12 md:col-span-3">
                <InputLabel label="Select State" required={true} />
                <SelectSearch
                  type="state select"
                  id={values.state_id}
                  options={states.map((state) => ({
                    label: state.name,
                    value: state.id,
                  }))}
                  value={parseInt(values.state_id)}
                  onChange={(value) => setFieldValue("state_id", value)}
                />
                <ErrorMessage name="state_id">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}

            {isCustomized === 0 && (
              <div className="col-span-12 md:col-span-6">
                <InputLabel label="Select Package" required={true} />
                <SelectSearch
                  options={packages.map((pkg) => ({
                    label: pkg.title,
                    value: pkg.id,
                  }))}
                  value={parseInt(values.package_id)}
                  onChange={(value) => setFieldValue("package_id", value)}
                />
                <ErrorMessage name="package_id">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}
            {isCustomized === 1 && (
              <div className="col-span-12 md:col-span-6">
                <InputLabel label="Package Name" required={true} />
                <Field name="package_name" className="field" />
                <ErrorMessage name="package_name">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}
            {isCustomized === 0 && (
              <div className="col-span-12 md:col-span-3">
                <InputLabel label="Select Itinerary" required={true} />
                <SelectSearch
                  options={itineraries}
                  value={values.itinerary_id}
                  onChange={(value) => setFieldValue("itinerary_id", value)}
                />
                <ErrorMessage name="itinerary_id">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}
            {isCustomized === 1 && (
              <>
                <div className="col-span-6 md:col-span-3">
                  <InputLabel label="From" required={true} />
                  <Field name="start_location" className="field" />
                  <ErrorMessage name="start_location">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <InputLabel label="To" required={true} />
                  <Field name="end_location" className="field" />
                  <ErrorMessage name="end_location">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </>
            )}
            {isCustomized === 0 && (
              <div className="col-span-12 md:col-span-3">
                <InputLabel label="Select Batch" required={true} />
                <SelectSearch
                  options={batches}
                  value={values.event_date}
                  onChange={(value) => {
                    if (value == "Others") {
                      setFieldValue("other_event_date", true);
                      setFieldValue("event_date", value);
                    } else {
                      setFieldValue("event_date", value);
                      setFieldValue("other_event_date", false);
                    }
                  }}
                />
                <ErrorMessage name="event_date">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}
            {isCustomized === 1 && (
              <>
                <div className="col-span-6 md:col-span-3">
                  <InputLabel label="Start Date" required={true} />
                  <DateInput
                    // minDate={new Date()}
                    value={values.event_date}
                    onDateChange={(value) =>
                      setFieldValue(
                        "event_date",
                        `${new Date(
                          value
                        ).getFullYear()}-${formatNumberToTwoDigit(
                          new Date(value).getMonth() + 1
                        )}-${formatNumberToTwoDigit(new Date(value).getDate())}`
                      )
                    }
                  />
                  <ErrorMessage name="event_date">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                {isCustomized === 1 && (
                  <div className="col-span-6 md:col-span-1">
                    <InputLabel label="No. of Days" required={true} />
                    <Field type="number" className="field" name="no_of_days" />
                    <ErrorMessage name="no_of_days">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                )}
              </>
            )}
            {values.other_event_date && (
              <div className="col-span-12 md:col-span-3">
                <InputLabel label="Event Date" required={true} />
                <DateInput
                  // minDate={new Date()}
                  value={values.event_date != "Others" ? values.event_date : ""}
                  onDateChange={(value) =>
                    setFieldValue(
                      "event_date",
                      `${new Date(
                        value
                      ).getFullYear()}-${formatNumberToTwoDigit(
                        new Date(value).getMonth() + 1
                      )}-${formatNumberToTwoDigit(new Date(value).getDate())}`
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>
      </Collapsible>
    </>
  );
}

export default PackageDetailsForm;
