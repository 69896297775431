import Select from "react-select";
import { renderToString } from "react-dom/server";

function SelectSearch({
  id,
  options,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  className,
  label,
  placeholder,
}) {
  // Helper function to extract plain text from HTML
  const extractPlainText = (label) => {
    const htmlString = renderToString(label);
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  // Custom filter logic
  const customFilter = (option, inputValue) => {
    const plainTextLabel = extractPlainText(option.label);
    return plainTextLabel.toLowerCase().includes(inputValue.toLowerCase());
  };
  return (
    <div className="hasHtmlLabel bg-white !py-0 !px-0 dark:bg-transparent">
      <Select
        id={id}
        isDisabled={disabled}
        classNamePrefix={className || "react-select"}
        value={
          value
            ? {
                value: value,
                label: options?.filter((opt) => opt.value === value)[0]?.label,
              }
            : null
        }
        options={
          options
            ? [{ label: `Select ${label ? label : ""}`, value: "" }, ...options]
            : []
        }
        onChange={(v) => onChange(v.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        ariaAutocomplete={false}
        placeholder={placeholder}
        filterOption={customFilter}
        // isSearchable={true}
      ></Select>
    </div>
  );
}

export default SelectSearch;
