import { useEffect, useRef, useState } from "react";
import { getAdminUrl } from "../../ServiceConfig";
import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import DataTable from "../components/common/DataTable";
import { toast } from "react-toastify";
import {
  deleteLeadCategory,
  deleteLeadSource,
  deleteLeadStatus,
  updateLeadCategory,
  updateLeadSource,
  updateLeadStatus,
} from "../services/LeadService";
import $ from "jquery";
import { isObjectEmpty } from "../../helpers/Helper";
import Modal from "react-modal";
import CategoryDeleteModal from "./CategoryDeleteModal";
import { MdClose } from "react-icons/md";
import StatusDeleteModal from "./StatusDeleteModal";
import SourceDeleteModal from "./SourceDeleteModal";

function LeadSettings() {
  const leadCategoryTable = useRef();
  const leadStatusTable = useRef();
  const leadSourceTable = useRef();

  const [openCategoryModal, setOpenCategoryModal] = useState(false)
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const [openSourceModal, setOpenSourceModal] = useState(false)

  const [selectedLeadCategory, setSelectedLeadCategory] = useState()
  const [selectedLeadStatus, setSelectedLeadStatus] = useState()
  const [selectedLeadSource, setSelectedLeadSource] = useState()

  const updateCategory = (value) => {
    if (!value.category) {
      toast.error("Category is required");
      return;
    }

    updateLeadCategory(value)
      .then((res) => {
        leadCategoryTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const updateStatus = (value) => {
    if (!value.status) {
      toast.error("Status is required");
      return;
    }

    updateLeadStatus(value)
      .then((res) => {
        leadStatusTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateSource = (value) => {
    if (!value.source) {
      toast.error("source is required");
      return;
    }

    updateLeadSource(value)
      .then((res) => {
        leadSourceTable.current.updateTable();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    const jsonData = JSON.stringify(cell.getRow().getData())
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
    return `
      <div class="delete-btn" data-id="${jsonData}" style="cursor: pointer;">
        <svg width="16px" height="16px" x="0px" y="0px" fill="red"
          viewBox="0 0 172.541 172.541" style="enable-background:new 0 0 172.541 172.541;" xml:space="preserve">
          <g>
            <path d="M166.797,25.078h-13.672h-29.971V0H49.388v25.078H19.417H5.744v15h14.806l10,132.463h111.443l10-132.463h14.805V25.078z
                 M64.388,15h43.766v10.078H64.388V15z M128.083,157.541H44.46L35.592,40.078h13.796h73.766h13.796L128.083,157.541z"/>
            <rect x="80.271" y="65.693" width="12" height="66.232"/>
            <rect x="57.271" y="65.693" width="12" height="66.232"/>
            <rect x="103.271" y="65.693" width="12" height="66.232"/>
          </g>
        </svg>
      </div>`;
  };


  const categoryColumns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Category",
      field: "category",
      minWidth: "100",
      vertAlign: "middle",
      editor: "input",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: ["required", "unique"],
      resizable: true,
    },

    {
      title: "leadquerycount",
      field: "leadquerycount",
      responsive: 0,
      width: "70",
      resizable: true,
      vertAlign: "middle",
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];
  const statusColumns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Status",
      field: "status",
      minWidth: "100",
      vertAlign: "middle",
      editor: "input",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: ["required", "unique"],
      resizable: true,
    },

    {
      title: "leadquerycount",
      field: "leadquerycount",
      responsive: 0,
      width: "70",
      resizable: true,
      vertAlign: "middle",
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const sourceColumns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Source",
      field: "source",
      minWidth: "200",
      vertAlign: "middle",
      editor: "input",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      validator: ["required", "unique"],
      resizable: true,
    },

    {
      title: "leadquerycount",
      field: "leadquerycount",
      responsive: 0,
      width: "70",
      resizable: true,
      vertAlign: "middle",
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const deleteRow = (data) => {
    let value = JSON.parse(data);

    // For status
    if (value.status) {
      if (!window.confirm("Do You really want to delete this lead status?"))
        return;
      if (value.leadquerycount) {
        setSelectedLeadStatus(value)
        setOpenStatusModal(true)
      }
      else {
        deleteLeadStatus(value.id)
          .then((res) => {
            leadStatusTable.current.reloadTable();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      }
    }
    // For Category
    else if (value.category) {
      if (!window.confirm("Do You really want to delete this lead category?"))
        return;
      if (value.leadquerycount) {
        setOpenCategoryModal(true)
        setSelectedLeadCategory(value)
      }
      else {
        deleteLeadCategory(value.id)
          .then((res) => {
            leadCategoryTable.current.reloadTable();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      }
    }
    // For Source
    else if (value.source) {
      if (!window.confirm("Do You really want to delete this lead source?"))
        return;
      if (value.leadquerycount) {
        setOpenSourceModal(true)
        setSelectedLeadSource(value)
      }
      else {
        deleteLeadSource(value.id)
          .then((res) => {
            leadSourceTable.current.reloadTable();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      }
    } else {
    }
  };

  useEffect(() => {
    $(document).on("click", ".delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let data = $(this).attr("data-id");
      deleteRow(data);
    });

    return () => {
      $(document).off("click", ".delete-btn");
    };
  }, []);

  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Lead Settings</H2>
      </ComponentHeader>

      <div className="flex flex-wrap">
        <div className="mb-8 w-full overflow-hidden px-2 md:w-1/2 lg:w-1/2 xl:w-1/3">
          <DataTable
            ref={leadCategoryTable}
            ajaxUrl={`${getAdminUrl()}/lead-category`}
            layout="fitColumns"
            columns={categoryColumns}
            search={true}
            onCellEdit={updateCategory}
            addRowBtn="Add Category"
            responsiveLayout="collapse"
          />
        </div>
        <div className="mb-8 w-full overflow-hidden px-2 md:w-1/2 lg:w-1/2 xl:w-1/3">
          <DataTable
            ref={leadStatusTable}
            ajaxUrl={`${getAdminUrl()}/lead-status`}
            layout="fitColumns"
            columns={statusColumns}
            search={true}
            onCellEdit={updateStatus}
            addRowBtn="Add Status"
            responsiveLayout="collapse"
          />
        </div>

        <div className="mb-8 w-full overflow-hidden px-2 md:w-1/2 lg:w-1/2 xl:w-1/3">
          <DataTable
            ref={leadSourceTable}
            ajaxUrl={`${getAdminUrl()}/lead-source`}
            layout="fitColumns"
            columns={sourceColumns}
            search={true}
            onCellEdit={updateSource}
            addRowBtn="Add Source"
            responsiveLayout="collapse"
          />
        </div>
      </div>

      {/* Category Modal */}
      <Modal
        isOpen={openCategoryModal}
        contentLabel="Delete Category Leads"
        // onAfterClose={getUpdatedData}
        className="absolute top-1/2 left-1/2 h-fit -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <div className="lg:mb-1 mb-4">
            <h2 className="text-xl font-bold">Delete Category Leads</h2>
            <p>({selectedLeadCategory?.category})</p>
          </div>
          <button
            onClick={() => setOpenCategoryModal(false)}
            className="absolute right-4 top-4 cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <CategoryDeleteModal selectedLeadCategory={selectedLeadCategory?.id} leadCategoryTable={leadCategoryTable} setOpenCategoryModal={setOpenCategoryModal} />
        </div>
      </Modal>


      {/* Status Modal */}
      <Modal
        isOpen={openStatusModal}
        contentLabel="Delete Status Leads"
        // onAfterClose={getUpdatedData}
        className="absolute top-1/2 left-1/2 h-fit -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <div className="lg:mb-1 mb-4">
            <h2 className="text-xl font-bold">Delete Status Leads</h2>
            <p>({selectedLeadStatus?.status})</p>
          </div>
          <button
            onClick={() => setOpenStatusModal(false)}
            className="absolute right-4 top-4 cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <StatusDeleteModal selectedLeadStatus={selectedLeadStatus?.id} leadStatusTable={leadStatusTable} setOpenStatusModal={setOpenStatusModal} />
        </div>
      </Modal>

      {/* Source Modal */}
      <Modal
        isOpen={openSourceModal}
        contentLabel="Delete Source Leads"
        // onAfterClose={getUpdatedData}
        className="absolute top-1/2 left-1/2 h-fit -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <div className="lg:mb-1 mb-4">
            <h2 className="text-xl font-bold">Delete Source Leads</h2>
            <p>({selectedLeadSource?.source})</p>
          </div>
          <button
            onClick={() => setOpenSourceModal(false)}
            className="absolute right-4 top-4 cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <SourceDeleteModal selectedLeadSource={selectedLeadSource?.id} leadSourceTable={leadSourceTable} setOpenSourceModal={setOpenSourceModal} />
        </div>
      </Modal>
    </div>
  );
}

export default LeadSettings;
