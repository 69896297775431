import { Link, useNavigate } from "react-router-dom";

import ComponentHeader from "../common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import PageContactForm from "./PageContactForm";

function AddPageContact() {
  const navigate = useNavigate();

  const onAddContact = (data) => {
    navigate(`/page-contacts`);
  };

  return (
    <div className="h-screen">
      <ComponentHeader className="mb-4 border-b">
        <h2 className="text-xl font-bold">Add Page Contact</h2>
        <Link
          to="/page-contacts"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      <PageContactForm onAdd={onAddContact} />
    </div>
  );
}

export default AddPageContact;
