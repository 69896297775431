import { admin } from "../../ServiceConfig";

export function getAllDepartments() {
  return admin.get(`/all-departments`);
}

export function addDepartment(data) {
  return admin.post(`/departments`, data);
}

export function deleteDepartmentById(id){
  return admin.delete(`/departments/${id}`)
}

export function updateDepartmentById(id, data){
  return admin.put(`/departments/${id}`, data)
}

export function getDepartmentById(id){
  return admin.get(`/departments/${id}`)
}

export function assignModulestoDepartment(data){
return admin.post(`/store-department-modules`, data)
}