import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
// import moment from "moment";
// import { MdClose } from "react-icons/md";
import ComponentHeader from "../components/common/ComponentHeader";
import InputLabel from "../components/inputs/InputLabel";
import DateRangeInput from "../components/inputs/DateRangeInput";
// import SelectSearch from "../components/inputs/SelectSearch";
import DataTable from "../components/common/DataTable";
import { getAdminUrl } from "../../ServiceConfig";
import H2 from "../../components/headings/H2";
// import { deleteJob, getAllJobProfiles } from "../services/JobServices";
// import { deleteLocation } from "../services/LocationServices";
import { toast } from "react-toastify";
import { deleteCAPById } from "../services/CapService";

function CAPApplications() {
  const navigate = useNavigate();
  const capTable = useRef();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  // const statusArray = ["Pending", "Selected", "Currently Not Hiring", "Rejected"]
  // const [roles, setRoles] = useState([])
  // const [message, setMessage] = useState("");

  const isFrozen = window.innerWidth <= 768 ? false : true;
  const deleteSelectedJob = (id) => {
    if (!window.confirm("Do You really want to delete this Opening?")) return;

    deleteCAPById(id)
      .then((res) => {
        toast.success(res.data.message);
        capTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // useEffect(() => {
  //   getAllJobProfiles()
  //   .then((res) => {
  //     setRoles(res.data.data);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  // }, [])
  
  useEffect(() => {
    capTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);
  // const deleteSelectedLocation = (id) => {
  //   deleteLocation(id)
  //     .then((res) => {
  //       toast.success(res.data.message);
  //       capTable.current.deleteRow(id);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="job-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>

       <i class="fas fa-eye"></i>
      </div>
      
  </div>
  `;
  // ----------- For Delete and edit
  //   return `
  // <div class="flex items-center gap-3">
  //     <div class="job-edit-btn cursor-pointer" data-id=${
  //       cell.getRow().getData().id
  //     }>

  //      <i class="fas fa-eye"></i>
  //     </div>
  //     <div class="job-delete-btn cursor-pointer text-red-500" data-id=${
  //       cell.getRow().getData().id
  //     }>
  //       <i class="fas fa-trash-alt"></i>
  //     </div>
  // </div>
  // `;
  };


  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };


  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Full Name",
      field: "first_name",
      minWidth: "220",
      vertAlign: "middle",

      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell) {
        const rowData = cell.getData();
        const firstName = rowData.first_name || "";
        const lastName = rowData.last_name || "";
        return `${firstName} ${lastName}`.trim();
      },
    },
    {
      title: "Email",
      minWidth: "220",
      field: "email",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Phone",
      field: "phone",
      resizable: true,
      headerSort: true,
      minWidth: "160",
      // hozAlign: "center",
      responsive: 0,
      vertAlign: "middle",
      formatter: function (cell) {
        const rowData = cell.getData();
        const countryCode = rowData.country_code || "";
        const phoneNumber = rowData.phone || "";
        return `+${countryCode} ${phoneNumber}`.trim();
      },
    },
    {
      title: "College",
      field: "institution",
      resizable: true,
      sorter: "string",
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      minWidth: "270",
      // hozAlign: "center",
      responsive: 0,
      vertAlign: "middle",
      // formatter: function (cell, formatterParams, onRendered) {
      //   //cell - the cell component
      //   //formatterParams - parameters set for the column
      //   //onRendered - function to call when the formatter has been rendered

      //   return cell.getRow().getData().department_name || "";
      //   //return the contents of the cell;
      // },
    },
    {
      title: "About",
      field: "description",
      minWidth: "250",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      // formatter: "textarea",
    },
    {
      title: "Instagram",
      field: "instagram_profile",
      resizable: true,
      minWidth: "250",
      responsive: 0,
      vertAlign: "middle",
    },
    {
      title: "Linkedin",
      vertAlign: "middle",
      headerSort: false,
      minWidth: "250",
      responsive: 0,
      field: "linkedin_profile",
    },
    {
      title: "Website",
      vertAlign: "middle",
      headerSort: false,
      minWidth: "250",
      responsive: 0,
      field: "blog_url",
    },
    {
      title: "Applied At",
      vertAlign: "middle",
      headerSort: false,
      minWidth: "190",
      responsive: 0,
      field: "created_at",
      formatter: function (cell, formatterParams) {
        var rawDate = cell.getValue();
        var formattedDate = new Date(rawDate).toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        return formattedDate;
      },
    },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
      frozen: isFrozen,
    },
  ];
  useEffect(() => {
    $(document).on("click", ".job-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/cap/application/${cell}`);
    });

    $(document).on("click", ".job-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedJob(dataId);
    });

    return () => {
      $(document).off("click", ".job-edit-btn");
      $(document).off("click", ".job-delete-btn");
    };
  }, []);

  // const onRowClick = (data) => {
  //   window.open(`/jobs/applications/${data.id}`, "_blank");
  // };

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>CAP Applications</H2>
      </ComponentHeader>
      <div className="relative h-full">
        <div className="my-8 rounded-md">
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={` inline-block whitespace-nowrap rounded-md border dark:bg-slate-700 bg-white p-8 py-2 text-lg font-semibold tracking-wide ${filterOpen
              ? "border-b-1 -mb-[1px] rounded-b-none px-8 pb-1 pt-2"
              : ""
              }`}
          >
            Filters
          </button>
          <div
            className={`flex grow flex-wrap dark:bg-slate-700 dark:text-white  items-center gap-4 rounded-md rounded-tl-none bg-white ${filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
              }`}
          >
            <div className="min-w-[250px]">
              <InputLabel label="Applied Date" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "created_at",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1
                        }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                        }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
            {/* <div className="min-w-[250px]">
              <InputLabel label="Job Profile" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "job_opening_id"
                  )?.value
                }
                options={roles?.map((role) => ({
                  label: `${role.role}`,
                  value: role.id,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "job_opening_id",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div> */}
            {/* <div className="min-w-[250px]">
              <InputLabel label="Application Status"  />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "status"
                  )?.value
                }
                options={statusArray?.map((onestatus, index) => ({
                  label: `${onestatus}`,
                  value: index,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "status",
                    type: "=",
                    value: value
                  })
                }
              />
            </div> */}
            <div>
              <InputLabel label="First Name"  />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "first_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Last Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "last_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Phone" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "phone",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <InputLabel label="Email" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "email",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <DataTable
          ref={capTable}
          ajaxUrl={`${getAdminUrl()}/campus-ambassador`}
          columns={columns}
          search={false}
          // rowClick={onRowClick}
          responsiveLayout="collapse"
        />
        {/* {message && (
          <div className="absolute bottom-2 right-4 max-h-[200px] max-w-[50%] overflow-auto rounded-xl bg-white p-4 px-8 shadow-lg">
            <button
              onClick={() => setMessage("")}
              className="absolute top-2 right-2"
            >
              <MdClose />
            </button>
            <p className="font-medium">Message</p>
            <p className="text-p-sm text-gray-500">{message}</p>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default CAPApplications;
