import React, { useEffect, useState } from 'react'
import { deleteLeadSource, getAllLeadSource } from '../services/LeadService'
import SelectSearch from '../components/inputs/SelectSearch'
import { FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'

const SourceDeleteModal = ({ selectedLeadSource, leadSourceTable, setOpenSourceModal }) => {
  const [allLeadSources, setAllLeadSources] = useState([])
  const [selected, setSelected] = useState()
  const allLeadSourcesGetter = async () => {
    const res = await getAllLeadSource()
    const arrayOfCats = res.data.data.map((oneCat) => ({
      label: oneCat?.source,
      value: oneCat.id
    }))
    setAllLeadSources(arrayOfCats.filter((oneCat) => oneCat.value !== selectedLeadSource))
  }
  useEffect(() => {
    allLeadSourcesGetter()
  }, [])

  const onDelete = () => {
    deleteLeadSource(selectedLeadSource, { new: selected })
      .then((res) => {
        leadSourceTable.current.reloadTable()
        setOpenSourceModal(false)
        toast.success("Deleted Successfully")
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }
  return (
    <div className='flex lg:flex-row flex-col lg:gap-4 gap-1 items-center'>
      <div className='min-w-[300px]'>
        <SelectSearch
          placeholder=""
          className='dark:text-black'
          value={selected
          }
          options={allLeadSources}
          onChange={(value) => {
            setSelected(value)
          }
          }
        />
      </div>
      <button
        disabled={!selected}
        onClick={onDelete}
        className="flex items-center lg:my-4 my-1 justify-center disabled:bg-opacity-50 p-2 bg-red-500 text-white rounded hover:bg-red-600"
      >
        <FaTrash className="w-4 h-4" /> Delete
      </button>
    </div>
  )
}

export default SourceDeleteModal