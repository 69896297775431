import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useMultiStepForm } from "../../../hooks/useMultiStepForm";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";

function ListingSeoForm({ formData, edit, onSuccess }) {

  const [listingSEOId, setListingSEOId] = useState()
  const [listingSEOTitle, setListingSEOTitle] = useState()
  const [listingSEOSlug, setListingSEOSlug] = useState()
  const handleNextForm = (id, title, slug) => {
    if (id && slug && title) {
      setListingSEOId(id)
      setListingSEOTitle(title)
      setListingSEOSlug(slug)
      next();
    }
  };

  useEffect(() => {
    if (formData) {
      setListingSEOId(formData?.id)
      setListingSEOTitle(formData?.title)
      setListingSEOSlug(formData?.slug)

    }
  }, [formData])
  const {
    steps,
    currentStepIndex,
    step,
    stepsCount,
    isFirstStep,
    isLastStep,
    goTo,
    back,
    next,
  } = useMultiStepForm([
    <FormStep1
      edit={edit}
      formData={formData}
      handleNextForm={handleNextForm}
    />,
    <FormStep2
      edit={edit}
      onSuccess={onSuccess}
      formData={formData}
      listingSEOId={listingSEOId}
      listingSEOTitle={listingSEOTitle}
      listingSEOSlug={listingSEOSlug}
    />,
  ]);


  const handleFromIndexClick = (index) => {
    if (index === 1 && !listingSEOId) return;
    goTo(index);
  };

  const renderStepIndexes = steps.map((step, index) => {
    return (
      <div
        key={index}
        onClick={() => handleFromIndexClick(index)}
        className={`h-8 ${index === currentStepIndex ? "bg-jw-green text-white" : ""
          } w-8 rounded-full border bg-white lg:p-[2px] p-[10px] ${index === currentStepIndex
            ? "border-2 border-jw-green"
            : "border-gray-400"
          }`}
      >
        <div
          className={`flex  h-full w-full cursor-pointer items-center justify-center rounded-full`}
        >
          {index + 1}
        </div>
      </div>
    );
  });

  if (window.innerWidth < 780) {
    return (<div className="">
      <div className="sticky flex gap-2 justify-center top-0 z-10  flex-row dark:bg-slate-900 bg-white py-1 lg:justify-between">
        <div className="lg:order-1">
          <button
            onClick={back}
            className="flex items-center justify-center gap-1 p-[2px] text-jw-green underline underline-offset-2"
          >
            <FaChevronLeft className={`rounded-full p-2 text-[2rem]  ${isFirstStep ? 'bg-slate-400 text-white' : 'bg-jw-green text-white'}`} />
          </button>
        </div>
        <div className="relative inline-flex  gap-4 px-2 py-[2px] border-gray-600 bg-slate-200 rounded-lg">
          <div className="absolute top-1/2 -z-[1] h-[2px] w-full -translate-y-1/2 bg-gray-400"></div>
          {renderStepIndexes}
        </div>

        <div className="lg:order-3">
          <button
            onClick={next}
            className={`flex items-center justify-center gap-1 p-[2px] text-jw-green underline underline-offset-2`}
          >
            <FaChevronRight className={`rounded-full p-2 text-[2rem]  ${isLastStep ? 'bg-slate-400 text-white' : 'bg-jw-green text-white'}`} />
          </button>
        </div>
      </div>
      <div className="relative z-0">{step}</div>
    </div>)
  }

  else {
    return <div>
      <div className="sticky top-0 z-10 flex flex-wrap overflow-auto bg-white py-1 lg:justify-between">
        <div className="relative inline-flex items-center justify-center gap-4 lg:order-2 lg:gap-8">
          <div className="absolute top-1/2 -z-[1] h-[2px] w-full -translate-y-1/2 bg-gray-400"></div>
          {renderStepIndexes}
        </div>
        <div className="lg:order-1">
          {!isFirstStep && (
            <button
              onClick={back}
              className="flex items-center justify-center gap-1 px-4 text-jw-green underline underline-offset-2"
            >
              <FaChevronLeft className="bg-jw-green text-white rounded-full p-1 text-3xl" /> Prev
            </button>
          )}
        </div>
        <div className="lg:order-3">
          {!isLastStep && (
            <button
              onClick={next}
              className="flex items-center justify-center gap-1 px-4 text-jw-green underline underline-offset-2"
            >
              Next <FaChevronRight className="bg-jw-green text-white rounded-full p-1 text-3xl" />
            </button>
          )}
        </div>
      </div>
      <div className="relative z-0">{step}</div>
    </div>
  }
}
export default ListingSeoForm;
