import { Link } from "react-router-dom";
import H2 from "../../components/headings/H2";
import ComponentHeader from "../components/common/ComponentHeader";
import { FaListAlt } from "react-icons/fa";
import LeadByStatus from "../widgets/leads/LeadByStatus";
import LeadByCategory from "../widgets/leads/LeadByCategory";
import LeadBySource from "../widgets/leads/LeadBySource";
import { useEffect, useState } from "react";
import {
  getLeadCategoryGraphData,
  getLeadSourceGraphData,
  getLeadStatusGraphData,
} from "../services/LeadService";
import LeadsCountPerDay from "../widgets/leads/LeadsCountPerDay";
import LeadVsBooking from "./LeadVsBooking";
import LeadsDateSelector from "./LeadsDateSelector";
import moment from "moment";
import LeadByPackage from "../widgets/leads/LeadByPackage";
import LeadByEmployee from "../widgets/leads/LeadByEmployee";

function LeadDashboard() {
  const [leadStatusData, setLeadStatusData] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);
  const [leadCategoryData, setLeadCategoryData] = useState([]);
  const [dateRange, setDateRange] = useState({
    start_date: moment().startOf("month"),
    end_date: moment().endOf("month"),
  });

  useEffect(() => {
    getLeadStatusGraphData()
      .then((res) => {})
      .catch((err) => {});

    getLeadSourceGraphData()
      .then((res) => {})
      .catch((err) => {});

    getLeadCategoryGraphData()
      .then((res) => {})
      .catch((err) => {});
  }, []);

  return (
    <div>
      <ComponentHeader className="flex-col border-b lg:flex-row">
        <div className="flex items-center gap-8">
          <H2 className="text-md lg:text-xl">Leads Dashboard</H2>
          <LeadsDateSelector
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </div>
        <Link
          to="/leads"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaListAlt /> View Leads
        </Link>
      </ComponentHeader>

      <div className="flex flex-col flex-wrap lg:flex-row">
        <div className="w-full p-2 lg:max-w-[50%] lg:basis-1/2">
          <LeadByStatus dateRange={dateRange} />
        </div>
        <div className="w-full p-2 lg:max-w-[50%] lg:basis-1/2">
          <LeadByCategory dateRange={dateRange} />
        </div>
        <div className="w-full p-2 lg:max-w-[50%] lg:basis-1/2">
          <LeadBySource dateRange={dateRange} />
        </div>
        <div className="w-full p-2 lg:max-w-[50%] lg:basis-1/2">
          <LeadsCountPerDay dateRange={dateRange} />
        </div>
        <div className="w-full p-2 lg:max-w-[50%] lg:basis-1/2">
          <LeadByEmployee dateRange={dateRange} />
        </div>
        <div className="w-full p-2 lg:max-w-[50%] lg:basis-1/2">
          <LeadByPackage dateRange={dateRange} />
        </div>
        <div className="w-full p-2">
          <LeadVsBooking dateRange={dateRange} />
        </div>
      </div>
    </div>
  );
}

export default LeadDashboard;
