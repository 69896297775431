import React, { useEffect, useState } from 'react'
import ComponentHeader from '../components/common/ComponentHeader'
import H2 from '../../components/headings/H2'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FaCheckSquare, FaListAlt, FaRegSquare } from 'react-icons/fa'
import { getAllModules, getAllModulesOption, getAllModulesOptionByRole, getSelectedModule } from '../services/ModuleServices'
import { MdExpandLess, MdExpandMore, MdIndeterminateCheckBox } from 'react-icons/md'
import CheckboxTree from 'react-checkbox-tree'
import { assignModulestoDepartment } from '../services/DepartmentServices'
import { toast } from 'react-toastify'

const AssignDepartmentModules = () => {
    const { id } = useParams()
    const [showing, setShowing] = useState([])
    const navigate = useNavigate()
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [modules, setModules] = useState();
    const [nameShowing, setnameShowing] = useState()
    const departmentDetail = async () => {
        try {
            const res = await getAllModulesOption()
            const res2 = await getSelectedModule(id)
            setnameShowing(res2.data.data.name)
            setChecked(res2.data.data.modules.map(onemodule => onemodule.id))            
            setModules(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    console.log(checked)
    useEffect(() => {
        const showingArray = []
        if (modules) {
            modules.map((oneParent, index) => {
                showingArray[index] = { value: oneParent.id, label: oneParent.name }
            })
        }
        setShowing(showingArray)
    }, [modules])
    const handleOncheck = (checked) => {
        setChecked(checked)
    }
    useEffect(() => {
        departmentDetail()
    }, [])
    const handleAssignModules = async () => {
        try {
            const submitData = {
                department_id: id,
                module_ids: checked
            }
            const res = await assignModulestoDepartment(submitData)
            toast.success(res.data.message)
            navigate('/employees/departments')
        } catch (error) {
            console.log(error)
        }

    }
    return (
        <div className="">
            <ComponentHeader className="mb-4 border-b">
                <H2>Add Modules to "{nameShowing}" Department</H2>
                <Link
                    to="/employees/departments"
                    className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
                >
                    <FaListAlt /> View All
                </Link>
            </ComponentHeader>
            <CheckboxTree
                nodes={showing}
                checked={checked}
                expanded={expanded}
                onCheck={handleOncheck}
                onExpand={(expanded) => setExpanded(expanded)}
                iconsClass="fa5"
                icons={{
                    check: <FaCheckSquare className="text-jw-green bg-white bg-transparent" />,
                    uncheck: <FaRegSquare />,
                    halfCheck: <MdIndeterminateCheckBox className="text-blue-700 bg-white bg-transparent" />,
                    expandClose: <MdExpandMore />,
                    expandOpen: <MdExpandLess />,
                    expandAll: "expandAll",
                    collapseAll: "collapseAll",
                    parentClose: <hr className="border-t-2 border-dashed border-gray-500" />,
                    parentOpen: <hr className="border-t-2 border-dashed border-gray-500" />,
                    leaf: <hr className="border-t-2 border-dashed border-gray-500" />,
                }}
            />
            <div className="text-center">
                <button onClick={handleAssignModules} className="btn-primary dark:bg-green-800">
                    Submit
                </button>
            </div>
        </div>
    )
}

export default AssignDepartmentModules