import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function Collapsible({ children, heading, selectedIndex, index, setSelectedIndex, hasErrors }) {
    const [open, setOpen] = useState(false)
    return <>
        {open ?
            <div className={`rounded-lg border   bg-white    dark:border-slate-700 dark:bg-slate-800 ${hasErrors ? 'border-red-600' : ' border-slate-200'}`}>
                <div onClick={() => { setOpen(false) }} className="flex items-center transition-all ease-in-out duration-300  justify-between cursor-pointer bg-[#D7F2FD]   lg:p-4 p-2">
                    <h3 className="font-bold">{heading} {hasErrors && <p className="text-red-500 text-sm"> There is/are one error or more errors in the section.</p>}</h3>
                    <FaChevronUp className="cursor-pointer" />
                </div>
                <div className="lg:p-4 p-2  ">
                    {children}</div></div>
            :
            <div onClick={() => { setOpen(true) }} className={`rounded-lg border  cursor-pointer bg-[#D7F2FD]  lg:p-4 p-2 dark:border-slate-700 dark:bg-slate-800 ${hasErrors ? 'border-red-600' : ' border-slate-200'}`}>
                <div className="flex items-center justify-between">
                    <h3 className="font-bold">{heading} {hasErrors && <p className="text-red-500 text-sm"> There is/are one error or more errors in the section.</p>} </h3> <FaChevronDown className="cursor-pointer" />
                </div>
            </div>}
    </>;
}
export default Collapsible;
