import React, { useState } from 'react'
import InputLabel from '../inputs/InputLabel';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import PhoneNumberInput from '../../../components/inputFields/PhoneInput';
import { checkUserExistbyPhone } from '../../services/CreditNotesServices';
import { toast } from 'react-toastify';
import { swalReact } from '../../../helpers/Helper';

const CreditNotesAddForm = ({ setCheckingUserData, checkingUserData }) => {
    const { values, setFieldValue } = useFormikContext();
    const [searchInProgress, setSearchInProgress] = useState(false);

    const handleCheckUserExist = async () => {
        if (searchInProgress) return;
        setSearchInProgress(true);
        if (checkingUserData?.country_code && checkingUserData?.phone) {
            try {
                const res = await checkUserExistbyPhone(checkingUserData)
                toast.success(res.data.message)
                if (res.data.data.first_name) {
                    setFieldValue('first_name', res?.data?.data?.first_name)
                    setFieldValue('last_name', res?.data?.data?.last_name)
                    setFieldValue('email', res?.data?.data?.email)
                } else {
                    swalReact({ title: "User Not Found!", text: "If You want to Create a New User Please Fill Personal Details and Submit..", showConfirmButton: true, confirmButtonText: true })
                    setFieldValue('first_name', "")
                    setFieldValue('last_name', "")
                    setFieldValue('email', "")
                }
            } catch (error) {
                toast.error("Problem in Checking User!!")
                console.log(error)
            }
        }
        else {
            toast.error("type a valid phone number")
        }
        setSearchInProgress(false);
    }

    return (

        <div className="my-4 grow">
            <div className="flex flex-wrap gap-4">
                {/* Phone Number */}
                <div className='flex w-full gap-4'>
                    <div className="grow basis-[45%] lg:max-w-[50%]">
                        <PhoneNumberInput
                            label="Phone Number"
                            onBlur={handleCheckUserExist}
                            phone={`+${values.country_code} ${values.phone}`}
                            onChange={(value, data) => {
                                if (value.slice(data.dialCode.length) !== checkingUserData?.phone) {
                                    setFieldValue(`country_code`, data.dialCode);
                                    setFieldValue(
                                        `phone`,
                                        value.slice(data.dialCode.length)
                                    );
                                    setCheckingUserData(
                                        {
                                            country_code: data.dialCode,
                                            phone: value.slice(data.dialCode.length)
                                        }
                                    )
                                }
                            }}
                        />
                        <ErrorMessage name="phone">
                            {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                    </div>
                    <div className="flex justify-center items-end">
                        <button
                            onClick={handleCheckUserExist}
                            className="bg-[#0183c0] text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-200"
                        >
                            Search
                        </button>
                    </div>
                </div>

                {/* //? First Name */}
                <div className="w-full lg:grow lg:basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="First Name" labelFor="first_name" required={true} />
                    <Field
                        className="field"
                        id="first_name"
                        name="first_name"
                    />
                    <ErrorMessage name="first_name">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>

                {/* //? Last Name */}
                <div className="w-full lg:grow lg:basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Last Name" labelFor="last_name" />
                    <Field
                        className="field"
                        id="last_name"
                        name="last_name"
                    />
                    <ErrorMessage name="last_name">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>

                {/* //? Email */}
                <div className="w-full lg:grow lg:basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Email" labelFor="email" required={true} />
                    <Field
                        className="field"
                        id="email"
                        name="email"
                    />
                    <ErrorMessage name="email">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>

                {/* //? Amount */}
                <div className="w-full lg:grow lg:basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Amount" labelFor="credit_amount" required={true} />
                    <Field
                        type="number"
                        className="field"
                        id="credit_amount"
                        name="credit_amount"
                    />
                    <ErrorMessage name="credit_amount">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>

                {/* //? Description */}
                <div className="w-full lg:grow lg:basis-[45%] lg:max-w-[50%]">
                    <InputLabel label="Description" labelFor="credit_type" required={true} />
                    <Field
                        as="textarea"
                        className="field"
                        id="credit_type"
                        name="credit_type"
                    />
                    <ErrorMessage name="credit_type">
                        {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                </div>
            </div>
            <div className="mt-4 text-center">
                <button className="btn-primary " type="submit">
                    Submit
                </button>
            </div>
        </div>
    )
}

export default CreditNotesAddForm