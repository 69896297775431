import { ErrorMessage, Field, Formik, Form } from "formik";

import FileInput from "../../../../components/inputFields/FileInput";
import InputLabel from "../../inputs/InputLabel";
import {
  addGiftCardTheme,
  getGiftCardCategories,
  updateGiftCardTheme,
  uploadGiftCardBlankImage,
  uploadGiftCardPreFilledImage,
} from "../../../services/GiftCardServices";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { FaPlusSquare } from "react-icons/fa";

import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import GiftCardCategoryForm from "./GiftCardCategoryForm";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { getUploadsUrl } from "../../../../ServiceConfig";

const GiftCardThemeFormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  prefilled_image: Yup.string().required("Required"),
  blank_image: Yup.string().required("Required"),
  gift_card_category_id: Yup.string().required("Required"),
  color: Yup.string().required("Required"),
});

function GiftCardThemeForm({ formData, edit }) {
  const navigate = useNavigate();

  const [giftCardCategories, setGiftCardCategories] = useState([]);
  const [categoryModal, setCategoryModal] = useState(false);

  const getCategories = () => {
    getGiftCardCategories().then((res) => {
      setGiftCardCategories(res.data.data.data);
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const addTheme = (values) => {
    addGiftCardTheme(values)
      .then((res) => {
        toast.success(res.data.message);
        navigate(`/gift-cards/subcategories`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateTheme = (values) => {
    console.log(values);
    updateGiftCardTheme(values)
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
        navigate(`/gift-cards/subcategories`);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          id: formData?.id,
          gift_card_category_id: formData?.gift_card_category_id
            ? formData.gift_card_category_id
            : "",
          name: formData?.name ? formData?.name : "",
          prefilled_image: formData?.prefilled_image
            ? formData?.prefilled_image
            : "",
          blank_image: formData?.blank_image ? formData?.blank_image : "",
          color: formData?.color ? formData?.color : "",
          status: formData?.status ? `${formData?.status}` : "1",
        }}
        validationSchema={GiftCardThemeFormSchema}
        onSubmit={(values) => {
          edit ? updateTheme(values) : addTheme(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>

            <div className="flex gap-4 rounded-lg border p-4 pb-12">
              {/* //? Banner Image */}
              <div className="grow">
                <InputLabel label="Blank Image" />
                <FileInput
                  noOfFilesAllowed={1}
                  value={`${getUploadsUrl()}/gift-cards/blank/${
                    values.blank_image
                  }`}
                  onChange={(file) => {
                    uploadGiftCardBlankImage({
                      image: file,
                    }).then((res) => {
                      setFieldValue(
                        "blank_image",
                        res.data.data[0].uploaded_image
                      );
                    });
                  }}
                />
                <ErrorMessage name={`blank_image`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="grow">
                <InputLabel label="Prefilled Image" />
                <FileInput
                  noOfFilesAllowed={1}
                  value={`${getUploadsUrl()}/gift-cards/prefilled/${
                    values.prefilled_image
                  }`}
                  onChange={(file) => {
                    uploadGiftCardPreFilledImage({ image: file }).then(
                      (res) => {
                        setFieldValue(
                          "prefilled_image",
                          res.data.data[0].uploaded_image
                        );
                      }
                    );
                  }}
                />
                <ErrorMessage name={`prefilled_image`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              <div className="grow">
                {/* //? Select Category */}
                <div className="">
                  <InputLabel label="Select Category" />

                  <div className="flex w-full items-center gap-1">
                    <Field
                      as="select"
                      name="gift_card_category_id"
                      className="field"
                    >
                      <option></option>
                      {giftCardCategories.length > 0 &&
                        giftCardCategories?.map((gc) => {
                          return <option value={gc.id}>{gc.name}</option>;
                        })}
                    </Field>
                    <button
                      type="button"
                      onClick={() => setCategoryModal(true)}
                    >
                      <FaPlusSquare />
                    </button>
                  </div>
                  <ErrorMessage name={`gift_card_category_id`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                {/* //? Card Name */}
                <div className="my-2">
                  <InputLabel label="Name" labelFor="name" />
                  <Field className="field" id="name" name="name" />
                  <ErrorMessage name="name">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                {/* //? Location Tag Line */}
                <div className="">
                  <InputLabel
                    label="Color Code"
                    instruction="(for text on card)"
                  />
                  <Field
                    type="color"
                    className="field h-8 cursor-pointer"
                    id="tag_line"
                    name="color"
                  />
                  <ErrorMessage name="color">
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                {/* //? Location Status */}
                <div className="my-4">
                  <div role="group" aria-labelledby="my-radio-group">
                    <InputLabel label="Status" />
                    <div className="flex items-center gap-12">
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="1" />
                        Active
                      </label>
                      <label className="flex items-baseline gap-1">
                        <Field type="radio" name="status" value="0" />
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary">
                {edit ? "Update Theme" : "Add Theme"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={categoryModal}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 h-fit min-w-[50%] max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">Add Gift Card Category</h2>
          <button
            onClick={() => setCategoryModal(false)}
            className="absolute right-4 top-4 rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <GiftCardCategoryForm
            getCategories={getCategories}
            closeModal={() => setCategoryModal(false)}
          />
        </div>
      </Modal>
    </div>
  );
}

export default GiftCardThemeForm;
