import React from "react";
import { getUploadsUrl } from "../../ServiceConfig";

const ProfileCardOption = ({ data }) => {
  return (
    <div className="flex cursor-pointer items-center space-x-4 rounded-md p-2 ">
      {/* Profile Image */}
      <img
        src={`${getUploadsUrl()}/employees/${
          data?.profile_pic || data?.cut_out_image
        }`}
        alt={`${data?.first_name} ${data?.last_name || ""}'s profile`}
        width={100}
        height={100}
        className="h-14 w-14 rounded-full border border-slate-500 object-cover p-1"
      />
      {/* User Details */}
      <div>
        <h4 className="text-lg font-bold text-gray-800 ">
          {data?.first_name} {data?.last_name || ""}
        </h4>
        <p className="text-sm font-bold text-gray-500 ">
          {data?.department_name} Department
        </p>
        <p className="text-sm text-gray-600 ">
          {data?.official_contact?.email}
        </p>
        {/* <p className="text-sm text-gray-600 ">
                                  {data?.official_contact?.phone}
                                </p> */}
      </div>
    </div>
  );
};

export default ProfileCardOption;
