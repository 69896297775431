import { Link, useNavigate } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";
import DataTable from "../common/DataTable";
import { useRef } from "react";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import { useEffect } from "react";
import $ from "jquery";
import { deleteGiftCard } from "../../services/GiftCardServices";
import { toast } from "react-toastify";

function GiftCardList() {
  const navigate = useNavigate();
  const giftCardTable = useRef();

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="giftcard-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="giftcard-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Blank Image",
      field: "blank_image",
      minWidth: "100",
      vertAlign: "middle",
      formatter: "image",
      formatterParams: {
        height: "60px",
        width: "100px",
        urlPrefix: `${getUploadsUrl()}/gift-cards/blank/`,
        urlSuffix: "",
      },
    },
    {
      title: "Prefilled Image",
      field: "prefilled_image",
      minWidth: "100",
      vertAlign: "middle",
      formatter: "image",
      formatterParams: {
        height: "60px",
        width: "100px",
        urlPrefix: `${getUploadsUrl()}/gift-cards/prefilled/`,
        urlSuffix: "",
      },
    },
    {
      title: "Name",
      field: "name",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Category",
      field: "category_name",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Color Code",
      field: "color",
      resizable: true,
      vertAlign: "middle",
      width: 150,
      formatter: function (cell, formatterParams, onRendered) {
        console.log(cell.getValue());
        return `<div style="width: 2rem; height: 2rem; border-radius: 100%; border: 1px solid #000000; margin: auto; background: ${cell.getValue()}"></div>`;
      },
    },
    {
      title: "Status",
      field: "status",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 1) {
          return "Active";
        } else {
          return "Inactive";
        }
      },
    },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  const deleteSelectedGiftCard = (id) => {
    if (!window.confirm("Do You really want to delete this gift card?")) return;
    deleteGiftCard(id)
      .then((res) => {
        toast.success(res.data.message);
        giftCardTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    $(document).on("click", ".giftcard-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/gift-cards/subcategories/edit/${cell}`);
    });

    $(document).on("click", ".giftcard-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedGiftCard(dataId);
    });

    return () => {
      $(document).off("click", ".giftcard-edit-btn");
      $(document).off("click", ".giftcard-delete-btn");
    };
  }, []);

  return (
    <div>
      <ComponentHeader className="border-b">
        <H2>Gift Cards SubCategories</H2>
        <Link
          to="/gift-cards/subcategories/add"
          className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="">
        <DataTable
          ref={giftCardTable}
          ajaxUrl={`${getAdminUrl()}/category-theme`}
          columns={columns}
          layout="fitColumns"
          search={true}
          responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default GiftCardList;
