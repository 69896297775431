import React, { useEffect, useState } from 'react'
import { getGiftCardCategories, getGiftCardThemeByCategoryId, uploadBulkGiftCard } from '../../../services/GiftCardServices';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import InputLabel from '../../inputs/InputLabel';
import SelectSearch from '../../inputs/SelectSearch';
import { saveAs } from "file-saver";
import sampleCSV from "../../../../assets/Gift-card-add-sample.csv"
import { useNavigate } from 'react-router-dom';
import { getUploadsUrl } from "../../../../ServiceConfig";

const bulkGiftCardSchema = Yup.object().shape({
    gift_card_category_id: Yup.string().required("Required"),
    gc_category_theme_id: Yup.string().required("Required"),
    greet: Yup.string(),
    message: Yup.string(),
    amount: Yup.number().min(1, "Should be greater than 0").max(50000).required("Required"),
});


const BulkIssueGiftCard = () => {
    const navigate = useNavigate()
    const [csvData, setCsvData] = useState([]);
    const [fileName, setFileName] = useState("");

    const [allGiftCardCategories, setAllGiftCardCategories] = useState([])
    const [allGiftCardThemes, setAllGiftCardThemes] = useState([])
    const [selectedGiftCardCategory, setSelectedGiftCardCategory] = useState()

    const [selectedGCTheme, setSleectedGCTheme] = useState()
    const [themeImage, setThemeImage] = useState(null)


    const getAllGiftCardCategory = async () => {
        try {
            const res = await getGiftCardCategories()
            setAllGiftCardCategories(res.data.data.data.map((onecat) => ({
                value: onecat.id,
                label: onecat.name
            })))
        } catch (error) {
            console.log(error)
        }
    }

    const getAllGiftCardTheme = async (id) => {
        try {
            const res = await getGiftCardThemeByCategoryId(id)
            setAllGiftCardThemes(res.data.data.map((onetheme) => ({
                label: onetheme.name,
                value: onetheme.id,
                image: onetheme.blank_image
            })))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllGiftCardCategory()
    }, [])

    useEffect(() => {
        if (selectedGiftCardCategory) {
            getAllGiftCardTheme(selectedGiftCardCategory)
        }
    }, [selectedGiftCardCategory])



    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            const reader = new FileReader();
            reader.onload = (event) => {
                const text = event.target.result;
                const data = csvToArray(text);
                setCsvData(data);
            };
            reader.readAsText(file);
        }
    };

    const csvToArray = (csvString) => {
        const rows = csvString.split("\n").map((row) => row.trim());
        const headers = rows[0].split(",");

        const data = rows.slice(1).map((row) => {
            const values = row.split(",");
            return headers.reduce((acc, header, index) => {
                acc[header] = values[index]?.trim() || "";
                return acc;
            }, {});
        });
        // Remove empty rows
        return data.filter((row) => Object.values(row).some((value) => value));
    };

    const handleUploadBulkData = async (data) => {
        if (csvData.length !== 0) {
            const submitData = csvData.map(item => ({
                ...item,
                ...data,
            }));
            try {
                const res = await uploadBulkGiftCard({ giftCards: submitData })
                toast.success(res.data.message)
                navigate(`/gift-cards/issued`)
            } catch (error) {
                console.log(error)
                toast.error(error.response.data.message)
            }
        }
        else {
            toast.error("Please Upload CSV Data")
        }
    }

    const downloadSample = () => {
        saveAs(`${sampleCSV}`, `Gift-Card-Bulk-Add-Sample.csv`);
    };

    useEffect(() => {
        if (selectedGCTheme) {
            setThemeImage(allGiftCardThemes.filter(thm => thm.value === selectedGCTheme)[0].image)
        } else {
            setThemeImage()
        }
    }, [selectedGCTheme])

    return (
        <div className='flex lg:flex-row flex-col w-full'>
            {/* Bulk Import */}

            <div className='lg:w-1/3 w-full flex justify-center'>
                <div className="mt-4 rounded-lg border-2 border-dashed p-4 h-fit w-[400px]">
                    <label className="mx-auto flex h-[100px]  items-center justify-center rounded-lg bg-gray-100 text-center text-lg font-bold">
                        <span>{fileName ? fileName : "Upload CSV File Only"}</span>
                        <span>
                            <input
                                type="file"
                                className="custom-file-input hidden"
                                id="inputGroupFile"
                                required
                                onChange={handleFileChange}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </span>
                    </label>
                    <div className="mb-4 text-right">
                        <button
                            onClick={downloadSample}
                            className="text-sm font-bold text-blue-500"
                        >
                            Download Sample CSV File
                        </button>
                    </div>
                </div>
            </div>
            <div className=" flex flex-wrap gap-4 lg:w-2/3 w-full">
                <Formik
                    enableReinitialize
                    initialValues={{
                        gift_card_category_id: "",
                        gc_category_theme_id: "",
                        greet: "",
                        message: "",
                        amount: 0,
                    }}
                    validationSchema={bulkGiftCardSchema}
                    onSubmit={(values) => {
                        handleUploadBulkData(values)
                    }}
                >

                    {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                            <div className=" flex flex-wrap gap-4 w-full">
                                <div className="my-4 grow">
                                    <div className="flex flex-wrap gap-4">
                                        <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                                            <InputLabel required={true} label="Gift Card Category" labelFor="gift_card_category_id" />
                                            <SelectSearch
                                                className='field'
                                                value={values.gift_card_category_id}
                                                options={
                                                    allGiftCardCategories
                                                }
                                                onChange={(value) => {
                                                    setFieldValue("gift_card_category_id", value)
                                                    setSelectedGiftCardCategory(value)
                                                    if (value !== selectedGiftCardCategory) {
                                                        setFieldValue("gc_category_theme_id", "")
                                                    }
                                                }}
                                            />
                                            <ErrorMessage name="gift_card_category_id">
                                                {(msg) => <div className="error-msg">{msg}</div>}
                                            </ErrorMessage>
                                        </div>

                                        <div className="my-4 grow basis-[45%] lg:max-w-[50%]">
                                            <InputLabel required={true} label="Gift Card Theme" labelFor="gc_category_theme_id" />
                                            <SelectSearch
                                                className='field'
                                                disabled={!selectedGiftCardCategory}
                                                value={values.gc_category_theme_id}
                                                options={
                                                    allGiftCardThemes
                                                }
                                                onChange={(value) => {
                                                    setSleectedGCTheme(value)
                                                    setFieldValue("gc_category_theme_id", value)
                                                }}
                                            />
                                            {
                                                themeImage &&
                                                <img
                                                    src={`${getUploadsUrl()}/gift-cards/blank/${themeImage}`}
                                                    className="max-w-[200px] h-auto border-2 border-black"
                                                />
                                            }
                                            <ErrorMessage name="gc_category_theme_id">
                                                {(msg) => <div className="error-msg">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                    </div>


                                    <div className="flex flex-wrap lg:gap-2 gap-2">
                                        <div className="my-2 grow basis-[45%] lg:max-w-[50%]">
                                            <InputLabel required={true} label="Amount" labelFor="amount" />
                                            <Field
                                                type="number"
                                                id="amount"
                                                name="amount"
                                                className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                                            />
                                            <ErrorMessage name="amount">
                                                {(msg) => <div className="error-msg">{msg}</div>}
                                            </ErrorMessage>
                                        </div>

                                        <div className="my-2 grow basis-[45%] lg:max-w-[50%]">
                                            <InputLabel label="Greet" labelFor="greet" />
                                            <Field
                                                as="textarea"
                                                id="greet"
                                                name="greet"
                                                className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                                            />
                                            <ErrorMessage name="greet">
                                                {(msg) => <div className="error-msg">{msg}</div>}
                                            </ErrorMessage>
                                        </div>

                                        <div className="my-2 grow basis-[45%] lg:max-w-[50%]">
                                            <InputLabel label="Message" labelFor="message" />
                                            <Field
                                                as="textarea"
                                                id="message"
                                                name="message"
                                                className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                                            />
                                            <ErrorMessage name="message">
                                                {(msg) => <div className="error-msg">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-4 text-center">
                                <button type="submit" className="btn-primary dark:bg-green-700">
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default BulkIssueGiftCard