import { Link, useNavigate } from "react-router-dom";
import ComponentHeader from "../../common/ComponentHeader";
import H2 from "../../../../components/headings/H2";
import { FaPlusSquare } from "react-icons/fa";
import DataTable from "../../common/DataTable";
import { useRef } from "react";
import { getAdminUrl } from "../../../../ServiceConfig";


function IssuedGiftCards() {
  const navigate = useNavigate();
  const issuedGiftCardTable = useRef();

  // const actionIcons = function (cell, formatterParams, onRendered) {
  //   return `<div class="employee-edit-btn" data-id=${cell.getRow().getData().id
  //     } style="display: flex; align-items: center; gap: 12px;">
  //   <div style="cursor: pointer; title="Edit">
  //   <svg
  //     x="0px"
  //     y="0px"
  //     width="16px"
  //     height="16px"
  //     viewBox="0 0 497.182 497.182"
  //     xml:space="preserve"
  //   >
  //     <g>
  //       <path
  //         d="M376.956,237.143l58.504-57.637v255.468c0,34.352-27.852,62.207-62.207,62.207H62.209
  // c-34.352,0-62.207-27.855-62.207-62.207V123.932c0-34.355,27.855-62.211,62.207-62.211h255.453l-59.848,58.234H58.597v318.359
  // h318.359V237.143z M377.469,56.755l62.967,62.985l20.993-20.986l-62.968-62.985L377.469,56.755z M434.211,0l-20.989,20.99
  // l62.968,62.968l20.989-20.989L434.211,0z M171.288,262.987l188.91-188.953l62.969,62.984l-188.91,188.938l-91.703,28.677
  // L171.288,262.987z M202.596,265.369l10.77,10.953l161.938-159.184l-10.77-10.953L202.596,265.369z"
  //       />
  //     </g>
  //   </svg></div>
  // </div>`;
  // };

  // const rowClick = (data) => {
  //   navigate(`/gift-cards/issued/edit/${data.id}`)
  // } 

  const rowFormatter = (row) => {
    if (row.getData().card_used_status === 1) {
      row.getElement().style.backgroundColor = "#bbf7d0";
    }
  };

  const columns = [
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Sender's Name",
      field: "sender_first_name",
      responsive: 0,
      minWidth: "230",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `<div>${cell.getValue()} ${cell.getRow().getData().sender_last_name} </div>`;
      },
    },
    {
      title: "Sender's Email",
      field: "sender_email",
      responsive: 0,
      minWidth: "230",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Sender's Phone",
      field: "sender_phone",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `<div>
          ${cell.getRow().getData().sender_country_code ? `+${cell.getRow().getData().sender_country_code}` : ""} ${cell.getValue() ? cell.getValue() : ""}</div>`;
      },
    },

    {
      title: "Recipient Name",
      field: "recipient_first_name",
      responsive: 0,
      minWidth: "230",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `<div>${cell.getValue() ? cell.getValue() : ""} ${cell.getRow().getData().recipient_last_name ? cell.getRow().getData().recipient_last_name : ""} </div>`;
      },
    },
    {
      title: "Recipient's Email",
      field: "recipient_email",
      responsive: 0,
      minWidth: "230",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Recipient Phone",
      field: "recipient_phone",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `<div>
          ${cell.getRow().getData().recipient_country_code ? `+${cell.getRow().getData().recipient_country_code}` : ""} ${cell.getValue() ? cell.getValue() : ""}</div>`;
      },
    },
    {
      title: "Category",
      field: "gift_card_category_name",
      responsive: 0,
      minWidth: "130",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Theme",
      field: "gc_category_theme_name",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Amount",
      field: "amount",
      responsive: 0,
      minWidth: "100",
      resizable: true,
      vertAlign: "middle",
    },

    {
      title: "Valid From",
      field: "valid_from",
      responsive: 0,
      minWidth: "130",
      resizable: true,
      vertAlign: "middle",
    },

    {
      title: "Valid Thru",
      field: "valid_thru",
      responsive: 0,
      minWidth: "130",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Card Generated",
      field: "card_file_generated",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
    },
    {
      title: "Physical Card",
      field: "is_applied_for_physical_card",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
    },
    {
      title: "Is used",
      field: "card_used_status",
      responsive: 0,
      minWidth: "100",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
    },
    {
      title: "Booking ID",
      field: "card_used_in_booking_id",
      responsive: 0,
      minWidth: "120",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return `<a style="color: blue; font-weight: bold" target="_blank" href="/bookings/${cell.getValue()}">${cell.getValue() ? cell.getValue() : ""}</a>`;
      },
    },
    {
      title: "Amount Used",
      field: "amount_used_in_booking",
      responsive: 0,
      minWidth: "130",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
    },
    {
      title: "Remaining Amt.",
      field: "amount_remaining_after_booking",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
    },
    {
      title: "Used By Name",
      field: "card_used_by_name",
      responsive: 0,
      minWidth: "230",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Used By Email",
      field: "card_used_by_email",
      responsive: 0,
      minWidth: "230",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Used By Phone",
      field: "card_used_by_phone",
      responsive: 0,
      minWidth: "140",
      resizable: true,
      vertAlign: "middle",
    },
  ];


  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Issued gift Cards</H2>
        <Link
          to="/gift-cards/issued/add"
          className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="flex items-center">
            <div className="h-4 w-4 rounded-full bg-[#bbf7d0]"></div>
            <p className="rounded-full px-1 py-2 text-[0.625rem] font-semibold leading-3  !text-green-600 dark:!text-green-600">
              Used Gift Cards
            </p>
          </div>
      <div className="h-full">
        <DataTable
          ref={issuedGiftCardTable}
          ajaxUrl={`${getAdminUrl()}/giftCard`}
          columns={columns}
          search={true}
          // rowClick={rowClick}
          responsiveLayout="collapse"
          rowFormatter={rowFormatter}
          perPage={25}
        />
      </div>
    </div>
  );
}

export default IssuedGiftCards;
