import React, { useState } from 'react'
import InputLabel from '../../inputs/InputLabel';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import PhoneNumberInput from '../../../../components/inputFields/PhoneInput';
import { useEffect } from 'react';
import { checkUserExistbyPhone } from '../../../services/CreditNotesServices';
import { toast } from 'react-toastify';
import { swalReact } from '../../../../helpers/Helper';

const GiftCardrecieverForm = ({ }) => {
  const { values, setFieldValue } = useFormikContext();
  const [searchInProgress, setSearchInProgress] = useState(false);

  const handleCheckUserExist = async () => {
    if (searchInProgress) return;
    setSearchInProgress(true);
    if (values?.recipient_country_code && values?.recipient_phone) {
      try {
        const res = await checkUserExistbyPhone({
          country_code: values?.recipient_country_code,
          phone: values?.recipient_phone
        })
        toast.success(res.data.message)
        if (res.data.data.first_name) {
          setFieldValue('recipient_first_name', res?.data?.data?.first_name)
          setFieldValue('recipient_last_name', res?.data?.data?.last_name)
          setFieldValue('recipient_email', res?.data?.data?.email)
        } else {
          // toast.error("User Not Found!")
          // swalReact({ title: "User Not Found!", text: "If You want to Create a New User Please Fill Personal Details and Submit..", showConfirmButton: true, confirmButtonText: true })
          setFieldValue('recipient_first_name', "")
          setFieldValue('recipient_last_name', "")
          setFieldValue('recipient_email', "")
        }
      } catch (error) {
        console.log(error)
      }
    }
    else {
      toast.error("type a valid phone number")
    }
    setSearchInProgress(false);
  }

  return (
    <div className="my-4 grow">
      <div className="flex flex-wrap gap-4">
        {/* Phone Number */}
        <div className="grow basis-[45%] lg:max-w-[50%]">
          <PhoneNumberInput
            required={false}
            label="Phone Number"
            onBlur={handleCheckUserExist}
            phone={`+${values.recipient_country_code} ${values.recipient_phone}`}
            onChange={(value, data) => {
              if (value.slice(data.dialCode.length) !== values?.recipient_phone) {
                setFieldValue(`recipient_country_code`, data.dialCode);
                setFieldValue(
                  `recipient_phone`,
                  value.slice(data.dialCode.length)
                );
              }
            }}
          />
          <ErrorMessage name="recipient_phone">
            {(msg) => <div className="error-msg">{msg}</div>}
          </ErrorMessage>
          <ErrorMessage name="recipient_country_code">
            {(msg) => <div className="error-msg">{msg}</div>}
          </ErrorMessage>
        </div>
        <div className="flex justify-center items-end">
          <button
            onClick={handleCheckUserExist}
            className="bg-[#0183c0] text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-200"
          >
            Search
          </button>
        </div>

        {/* //? First Name */}
        <div className="grow basis-[45%] lg:max-w-[50%]">
          <InputLabel label="First Name" labelFor="recipient_first_name" />
          <Field
            className="field"
            id="recipient_first_name"
            name="recipient_first_name"
          />
          <ErrorMessage name="recipient_first_name">
            {(msg) => <div className="error-msg">{msg}</div>}
          </ErrorMessage>
        </div>

        {/* //? Last Name */}
        <div className="grow basis-[45%] lg:max-w-[50%]">
          <InputLabel label="Last Name" labelFor="recipient_last_name" />
          <Field
            className="field"
            id="recipient_last_name"
            name="recipient_last_name"
          />
          <ErrorMessage name="recipient_last_name">
            {(msg) => <div className="error-msg">{msg}</div>}
          </ErrorMessage>
        </div>

        {/* //? Email */}
        <div className="grow basis-[45%] lg:max-w-[50%]">
          <InputLabel label="Email" labelFor="recipient_email" />
          <Field
            className="field"
            id="recipient_email"
            name="recipient_email"
          />
          <ErrorMessage name="recipient_email">
            {(msg) => <div className="error-msg">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
    </div>
  )
}

export default GiftCardrecieverForm