import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdClose } from "react-icons/md";
import { uploadImage } from "../../services/CommonServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ReactSortable } from "react-sortablejs";

import Modal from "react-modal";
import ImageEditor from "./ImageEditor";
import { getUploadsUrl } from "../../../ServiceConfig";
import { toast } from "react-toastify";
import { swalReact } from "../../../helpers/Helper";

const reorderImages = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function ImageInput({
  path,
  package_slug,
  allowedFiles,
  images,
  setImages,
  cropRatios,
  onDelete,
  thumbSize,
  onImageCrop,
  reorder,
  listingSEO = false
}) {
  const [files, setFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState();
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [editImageSource, setEditImageSource] = useState();
  const [stopDropzone, setStopDropzone] = useState(true);

  const onChange = (files) => {
    files.forEach((file, index) => {
      if (file.uploaded_image) files.splice(index, 1);
    });

    uploadImage(
      { image: files, package_slug: package_slug ?? null },
      path
    ).then((res) => {
      setEditImageSource(res.data.data[0].uploaded_image);
      setIsImageEditorOpen(true);
      // if (images) {
      allowedFiles === 1
        ? setImages(res.data.data)
        : setImages([...images, ...res.data.data]);
      // } else {
      //   setImages(res.data.data);
      // }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: allowedFiles,
    onDrop: (acceptedFiles) => {
      let selectedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      let allFiles = [...files, ...selectedFiles];
      // setFiles(allFiles);
      onChange(allFiles);

      setPreviewFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      );
    },
  });

  const deleteImage = (e, index) => {
    e.stopPropagation();
    let f = [...images];
    f.splice(index, 1);
    setImages(f);
    onDelete(images[index]);
  };

  // React sweet laert fol deletion
  const showDeleteSwal = (e, index) => {
    swalReact({
      title: "Do You Really want to delete the image?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteImage(e, index);
      } else if (result.isDismissed) {
      }
    });
  };

  const setCaption = (value, index) => {
    let imgs = [...images];
    imgs[index].caption = value;
    setImages(imgs);
  };

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        toast.error("Please drop the category inside the list");
        return;
      }

      const reorderedItems = reorderImages(
        images,
        result.source.index,
        result.destination.index
      );
      const submitData = reorderedItems.map(({ id, order }, index) => ({
        image_id: `${id}`,
        order: `${index}`,
      }));
      reorder(submitData);
    },
    [images]
  );

  const handleSetImages = (e) => {
    const updatedImages = e.map((item, index) => ({
      ...item,
      order: index, // Set the order key to be consecutive numbers starting from 1
    }));
    setImages(updatedImages);
  };

  const thumbs = reorder
    ? images &&
      images.length > 0 && (
        <ReactSortable
          className="flex flex-wrap lg:gap-11"
          list={images}
          setList={handleSetImages}
        >
          {images?.map((file, index) => (
            <div
              key={index}
              className="relative"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                onClick={(e) => showDeleteSwal(e, index)}
                className="font-bolder absolute -top-2 -right-2 cursor-pointer rounded-full border bg-red-800 p-1 text-white"
              >
                <MdClose className="font-bold" />
              </div>
              <div className={`flex h-40 w-40 rounded-md border p-1`}>
                <div className=" min-w-0 overflow-hidden">
                  <img
                    src={`${getUploadsUrl()}/${path}/${
                      file.image ? file.image : file.uploaded_image
                    }`}
                    className="block h-full w-auto"
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview);
                    }}
                    alt={file.caption}
                  />
                </div>
              </div>
              <input
                type="text"
                name="img-caption"
                id=""
                value={file.caption ? file.caption : ""}
                onChange={(e) => setCaption(e.target.value, index)}
                // onChange={(e) => {}}
                placeholder="Caption"
                className="mt-1 block w-40 rounded-md border p-1 text-sm focus:border-gray-400 focus:outline-none"
              />
              {cropRatios && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsImageEditorOpen(true);
                    setEditImageSource(
                      file.image ? file.image : file.uploaded_image
                    );
                  }}
                  className="text-sm font-bold text-jw-green"
                >
                  Crop Again
                </button>
              )}
            </div>
          ))}
        </ReactSortable>
      )
    : images?.map((file, index) => (
        <div
          key={index}
          className="relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
         {!listingSEO &&  <div
            onClick={(e) => showDeleteSwal(e, index)}
            className="absolute -top-2 -right-2 cursor-pointer rounded-full border bg-white/50 p-1"
          >
            <MdClose />
          </div>}
          <div className={`flex h-40 w-40 rounded-md border p-1`}>
            <div className=" min-w-0 overflow-hidden">
              <img
                src={`${getUploadsUrl()}/${path}/${
                  file.image ? file.image : file.uploaded_image
                }`}
                className="block h-full w-auto"
                // Revoke data uri after image is loaded
                onLoad={() => {
                  URL.revokeObjectURL(file.preview);
                }}
                alt={file.caption}
              />
            </div>
          </div>
         {listingSEO ? <></> : <input
            type="text"
            name="img-caption"
            id=""
            value={file.caption ? file.caption : ""}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setCaption(e.target.value, index)}
            // onChange={(e) => {}}
            placeholder="Caption"
            className="mt-1 block w-40 rounded-md border p-1 text-sm focus:border-gray-400 focus:outline-none"
          />}
          {cropRatios && (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsImageEditorOpen(true);
                setEditImageSource(
                  file.image ? file.image : file.uploaded_image
                );
              }}
              className="text-sm font-bold text-jw-green"
            >
              Crop Again
            </button>
          )}
        </div>
      ));
  return (
    <div>
      <section className="w-full">
        <div
          {...getRootProps({
            className: "dropzone flex justify-center",
          })}
        >
          <div
            onClick={(e) => {
              if (!stopDropzone) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            className="flex min-h-[10rem] w-full max-w-full items-center justify-center rounded-lg border-2 border-dashed py-4 text-center"
          >
            <div className="flex h-full w-full flex-col items-center">
              {/* <img src={previewFile?.preview} alt="" className="mx-auto h-full" /> */}
              <aside className="flex flex-wrap gap-4 px-2">{thumbs}</aside>

              <input {...getInputProps()} />
              <p className="mt-4">
                Drag 'n' drop some files here, or click to select files
              </p>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={isImageEditorOpen && cropRatios?.length > 0}
        onRequestClose={() => setIsImageEditorOpen(false)}
        contentLabel="image cropper"
        className="absolute left-1/2 top-1/2 z-10 w-full max-w-[1200px] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div
          className="absolute right-4 top-4 z-10 rounded-full border bg-white p-2"
          onClick={() => setIsImageEditorOpen(false)}
        >
          <MdClose />
        </div>
        <div className="p-4">
          <ImageEditor
            source={`${path}/${editImageSource}`}
            isImageEditorOpen={isImageEditorOpen}
            setIsImageEditorOpen={setIsImageEditorOpen}
            cropRatios={cropRatios}
            onImageCrop={onImageCrop}
          />
        </div>
      </Modal>
    </div>
  );
}

export default ImageInput;

// OLD code for reordereing images in a grid

{
  /* <DragDropContext
      onDragStart={() => {
        setStopDropzone(false)
      }}
      onDragEnd={(e) => {
        setStopDropzone(true)
        onDragEnd(e)
      }}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            className="flex gap-2 overflow-auto"
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {showingArray?.map((file, index) => (
              <Draggable key={`${file.id}`} draggableId={`${file.id}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    className="flex justify-between font-bold"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div key={index} className="relative" onClick={(e) => {
                      e.stopPropagation()
                      // window.open(value, '_blank')
                    }}>
                      <div
                        onClick={(e) => showDeleteSwal(e, index)}
                        className="absolute -top-2 -right-2 cursor-pointer rounded-full border bg-white/50 p-1"
                      >
                        <MdClose />
                      </div>
                      <div className={`flex h-40 w-40 rounded-md border p-1`}>
                        <div className=" min-w-0 overflow-hidden">
                          <img
                            src={`${getUploadsUrl()}/${path}/${file.image ? file.image : file.uploaded_image
                              }`}
                            className="block h-full w-auto"
                            // Revoke data uri after image is loaded
                            onLoad={() => {
                              URL.revokeObjectURL(file.preview);
                            }}
                            alt={file.caption}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        name="img-caption"
                        id=""
                        value={file.caption ? file.caption : ""}
                        onChange={(e) => setCaption(e.target.value, index)}
                        // onChange={(e) => {}}
                        placeholder="Caption"
                        className="mt-1 block w-40 rounded-md border p-1 text-sm focus:border-gray-400 focus:outline-none"
                      />
                      {cropRatios && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsImageEditorOpen(true);
                            setEditImageSource(file.image ? file.image : file.uploaded_image);
                          }}
                          className="text-sm font-bold text-jw-green"
                        >
                          Crop Again
                        </button>
                      )}
                    </div>

                  </div>
                )}
              </Draggable>
            ))} {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>  */
}
