import React, { useEffect, useState } from "react";
import FileInput from "../../../components/inputFields/FileInput";

import { createRole, updateRole } from "../../services/RoleServices";
import InputLabel from "../inputs/InputLabel";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import { getUploadsUrl } from "../../../ServiceConfig";

// -----------------------------------------------------------
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import KeywordsInput from "../inputs/KeywordsInput";
import ModulesAndPermissionsForm from "./ModulesAndPermissionsForm";
import CollapsibleCard from "./CollapsibleCard";
import {  getAllModulesOptionByRole } from "../../services/ModuleServices";
import { MdExpandLess } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";
import { MdIndeterminateCheckBox } from "react-icons/md";
import H2 from "../../../components/headings/H2";

import CheckboxTree from 'react-checkbox-tree';
import { Fa500Px, FaCheckSquare , FaRegSquare } from "react-icons/fa";
import { getAllDepartments } from "../../services/DepartmentServices";
import SelectSearch from "../inputs/SelectSearch";

const countryFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
    department_id: Yup.string().required("Required")
  // has_riders: Yup.number().required("Required"),
});

function RoleForm({ formData, edit, onSuccess, roleId }) {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const { setLoading } = useLoader();
  const [modules, setModules] = useState();
  const [showing, setShowing] = useState([])
  const [allDepartments, setAllDepartments] = useState([])
console.log(formData, edit, onSuccess)
  useEffect(() => {
    const showingArray = []
    if (modules) {
      modules.map((oneParent, index) => {
        showingArray[index] = { value: oneParent.name, label: oneParent.name, children: [] }
        const childrenArray = []
        oneParent.permissions.map((oneChild, index) => {
          childrenArray[index] = { value: oneChild.name, label: oneChild.name }
        })
        showingArray[index].children = childrenArray
      })
    }
    setShowing(showingArray)
  }, [modules])

  console.log(showing, modules)

  const allDepartmentsGetter = async() => {
    try {
      const res = await getAllDepartments()
      setAllDepartments(res.data.data.map(oneDept => (
        {
          label: oneDept.name,
          value: oneDept.id
        }
      )))  
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if(formData?.permissions){
      setChecked(formData.permissions)
    }

    if(edit){
      getAllModulesOptionByRole(roleId)
      .then((res) => {
        setModules(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    }
      allDepartmentsGetter()
  }, []);

// handle what happens on check
  const handleOncheck = (checked) => {
    setChecked(checked)
console.log(checked, "got")
  }

  // ? Add new country
  const addRole = (values) => {
    let data = Object.assign({}, values);
    createRole(data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // ? Update country
  const editRole = (values) => {
    let data = Object.assign({}, values);
    updateRole(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        onSuccess(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: formData?.name,
          permissions: formData?.permissions,
          department_id: formData?.department_id
        }}
        validationSchema={countryFormSchema}
        onSubmit={(values) => {
          // console.log(values)
          edit ? editRole({name: values.name, permissions: checked, department_id: values?.department_id}) : addRole({name: values.name, permissions: checked, department_id: values?.department_id});
          // editRole({name: values.name, permissions: checked})
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex gap-4">
              <div className="grow">
                <div className="flex flex-wrap gap-4">
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Name" labelFor="name" required={true}/>
                    <Field
                      className="block w-full rounded-md border-2 py-1 px-2 dark:bg-slate-600"
                      id="name"
                      name="name"
                    />
                    <ErrorMessage name="name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="max-w-[50%] grow basis-[45%]">
                    <InputLabel label="Department" labelFor="department_id" required={true}/>
                    <SelectSearch
                    disabled={edit}
                      options={allDepartments}
                      value={values.department_id}
                      onChange={(e) => {
                        setFieldValue("department_id", e)
                      }}
                    />
                    <ErrorMessage name="department_id">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>&nbsp;</p>
              <br />
            </div>
           {edit ? <>
            <H2>Assign Permissions : </H2>
            <CheckboxTree
              nodes={showing}
              checked={checked}
              expanded={expanded}
              onCheck={handleOncheck}
              onExpand={(expanded) => setExpanded(expanded)}
              iconsClass="fa5"
              icons={{
                check: <FaCheckSquare  className="text-jw-green bg-white bg-transparent"/>,
                uncheck: <FaRegSquare />,
                halfCheck: <MdIndeterminateCheckBox className="text-blue-700 bg-white bg-transparent" />,
                expandClose:  <MdExpandMore />,
                expandOpen: <MdExpandLess/>,
                expandAll: "expandAll",
                collapseAll: "collapseAll",
                parentClose: <hr className="border-t-2 border-dashed border-gray-500" />,
                parentOpen: <hr className="border-t-2 border-dashed border-gray-500" />,
                leaf: <hr className="border-t-2 border-dashed border-gray-500" />,
              }}
            />
            </> : <></>}
            {/* Mapping over cardsData to create multiple collapsible cards */}
            {/* {modules &&
              modules.map((card, index) => (
                <CollapsibleCard key={index} title={card.name} content={card} />
              ))} */}

            <div className="text-center">
              <button type="submit" className="btn-primary dark:bg-green-800">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RoleForm;
